//------------------------------------------------------------------------------
// IMPORTS

@import "base";

@import "alert";
@import "badge";
@import "buttons";
@import "button-group";
@import "card";
@import "columns";
@import "dropdown";
@import "forms";
@import "grid";
@import "images";
@import "list-group";
@import "modal";
@import "nav";
@import "navbar";
@import "pagination";
@import "progress";
@import "tables";
@import "toasts";
@import "tooltip";
@import "transitions";
@import "type";
@import "utilities";

@import "data-table";
@import "fancybox";
@import "file-tree";

/* -----------------------------------------------------------------------------
  DEFAULTS
*/

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* -----------------------------------------------------------------------------
  HEADER
*/

@keyframes color-animation {
  0% {
    background-position: 22% 0%;
  }

  100% {
    background-position: 100% 0%;
  }
}

.color-animation {
  //animation: color-animation 8s linear infinite;
  background: linear-gradient(90deg, #00B33A, #00ACF1, #00B33A, #FFA000, #00B33A, #00ACF1, #00B33A, #FFA000, #00B33A);
  background-position: 22% 0;
  background-size: 300% 100%;
}

.color-header {
  @extend .color-animation;
  height: rem(14);
}

/* -----------------------------------------------------------------------------
  MAIN
*/

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  display: block;
  flex: 1 0 auto;
}

/* -----------------------------------------------------------------------------
  LOGIN
*/

@include media-breakpoint-up(lg) {
  .login {
    min-height: 100vh;
  }
}

/* -----------------------------------------------------------------------------
  CKEDITOR
*/

.django-ckeditor-widget {
  display: block !important;
}

//.cke_notification_close {
//  z-index: 900 !important;
//}

//textarea[data-html-textarea="ckeditor"].is-invalid {
//  & ~ .ck.ck-editor {
//    border-color: var(--x-danger) !important;
//  }
//}

.django-ckeditor-widget.is-invalid {
  border-style: solid;
  border-color: red;
  & ~ .invalid-feedback {
    display: block;
  }
}

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

.custom_select_datatable {
  height: calc(var(--x-input-line-height) * 0.5em + var(--x-input-padding-y) * 2 + var(--x-input-height-border)),
}
