/* -----------------------------------------------------------------------------
  ALERT
*/
.alert {
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
  margin-bottom: 1rem;
  padding: 0.75rem 0.75rem;
  position: relative; }

.alert-heading {
  color: inherit; }

.alert-dismissible .close {
  color: inherit;
  padding: 0.75rem 0.75rem;
  position: absolute;
  right: 0;
  top: 0; }

.alert-black {
  background-color: #cccccc;
  border-color: #cccccc;
  color: black; }
  .alert-black hr {
    border-top-color: #bfbfbf; }
  .alert-black .alert-link {
    color: black; }
  .alert-black.alert-dismissible .close {
    fill: black; }

.alert-gray-200 {
  background-color: #fbfbfc;
  border-color: #fbfbfc;
  color: #797b7c; }
  .alert-gray-200 hr {
    border-top-color: #ececf1; }
  .alert-gray-200 .alert-link {
    color: #606162; }
  .alert-gray-200.alert-dismissible .close {
    fill: #606162; }

.alert-gray-300 {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #737678; }
  .alert-gray-300 hr {
    border-top-color: #e9ecef; }
  .alert-gray-300 .alert-link {
    color: #5a5c5e; }
  .alert-gray-300.alert-dismissible .close {
    fill: #5a5c5e; }

.alert-gray-400 {
  background-color: #f5f6f8;
  border-color: #f5f6f8;
  color: #6b6e71; }
  .alert-gray-400 hr {
    border-top-color: #e6e9ee; }
  .alert-gray-400 .alert-link {
    color: #525557; }
  .alert-gray-400.alert-dismissible .close {
    fill: #525557; }

.alert-danger {
  background-color: #f8d7da;
  border-color: #f8d7da;
  color: #721c24; }
  .alert-danger hr {
    border-top-color: #f4c1c6; }
  .alert-danger .alert-link {
    color: #491217; }
  .alert-danger.alert-dismissible .close {
    fill: #491217; }

.alert-dark {
  background-color: #d3d3d4;
  border-color: #d3d3d4;
  color: #111315; }
  .alert-dark hr {
    border-top-color: #c6c6c7; }
  .alert-dark .alert-link {
    color: black; }
  .alert-dark.alert-dismissible .close {
    fill: black; }

.alert-info {
  background-color: #d1ecf1;
  border-color: #d1ecf1;
  color: #0c5460; }
  .alert-info hr {
    border-top-color: #bde4eb; }
  .alert-info .alert-link {
    color: #062c33; }
  .alert-info.alert-dismissible .close {
    fill: #062c33; }

.alert-light {
  background-color: #fefefe;
  border-color: #fefefe;
  color: #818182; }
  .alert-light hr {
    border-top-color: #f1f1f1; }
  .alert-light .alert-link {
    color: #686868; }
  .alert-light.alert-dismissible .close {
    fill: #686868; }

.alert-primary {
  background-color: #fce5cc;
  border-color: #fce5cc;
  color: #7c4201; }
  .alert-primary hr {
    border-top-color: #fbd9b4; }
  .alert-primary .alert-link {
    color: #492701; }
  .alert-primary.alert-dismissible .close {
    fill: #492701; }

.alert-secondary {
  background-color: #e9efd3;
  border-color: #e9efd3;
  color: #4b5a13; }
  .alert-secondary hr {
    border-top-color: #e0e8c0; }
  .alert-secondary .alert-link {
    color: #28300a; }
  .alert-secondary.alert-dismissible .close {
    fill: #28300a; }

.alert-tertiary {
  background-color: #cce1e9;
  border-color: #cce1e9;
  color: #00364c; }
  .alert-tertiary hr {
    border-top-color: #bad7e1; }
  .alert-tertiary .alert-link {
    color: #001219; }
  .alert-tertiary.alert-dismissible .close {
    fill: #001219; }

.alert-success {
  background-color: #e9efd3;
  border-color: #e9efd3;
  color: #4b5a13; }
  .alert-success hr {
    border-top-color: #e0e8c0; }
  .alert-success .alert-link {
    color: #28300a; }
  .alert-success.alert-dismissible .close {
    fill: #28300a; }

.alert-warning {
  background-color: #fff3cd;
  border-color: #fff3cd;
  color: #856404; }
  .alert-warning hr {
    border-top-color: #ffedb4; }
  .alert-warning .alert-link {
    color: #533f03; }
  .alert-warning.alert-dismissible .close {
    fill: #533f03; }

.alert-white {
  background-color: white;
  border-color: white;
  color: #858585; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }
  .alert-white.alert-dismissible .close {
    fill: #6c6c6c; }

/* -----------------------------------------------------------------------------
  BADGE
*/
.badge {
  border-radius: 0.3125rem;
  display: inline-block;
  font-size: 75%;
  line-height: 1;
  padding: 0.25rem 0.375rem;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap; }
  a.badge:hover {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.badge-pill {
  border-radius: 0.625rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem; }

.badge-black {
  background-color: #000;
  color: #FFF; }
  a.badge-black:hover {
    background-color: black;
    color: #FFF; }
  a.badge-black:focus, a.badge-black.focus {
    box-shadow: 0 0 0 0.1875rem rgba(0, 0, 0, 0.5);
    outline: 0; }

.badge-gray-200 {
  background-color: #E9ECEF;
  color: #212529; }
  a.badge-gray-200:hover {
    background-color: #cbd3da;
    color: #212529; }
  a.badge-gray-200:focus, a.badge-gray-200.focus {
    box-shadow: 0 0 0 0.1875rem rgba(233, 236, 239, 0.5);
    outline: 0; }

.badge-gray-300 {
  background-color: #DEE2E6;
  color: #212529; }
  a.badge-gray-300:hover {
    background-color: #c1c9d0;
    color: #212529; }
  a.badge-gray-300:focus, a.badge-gray-300.focus {
    box-shadow: 0 0 0 0.1875rem rgba(222, 226, 230, 0.5);
    outline: 0; }

.badge-gray-400 {
  background-color: #CED4DA;
  color: #212529; }
  a.badge-gray-400:hover {
    background-color: #b1bbc4;
    color: #212529; }
  a.badge-gray-400:focus, a.badge-gray-400.focus {
    box-shadow: 0 0 0 0.1875rem rgba(206, 212, 218, 0.5);
    outline: 0; }

.badge-danger {
  background-color: #DC3545;
  color: #FFF; }
  a.badge-danger:hover {
    background-color: #bd2130;
    color: #FFF; }
  a.badge-danger:focus, a.badge-danger.focus {
    box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.5);
    outline: 0; }

.badge-dark {
  background-color: #212529;
  color: #FFF; }
  a.badge-dark:hover {
    background-color: #0a0c0d;
    color: #FFF; }
  a.badge-dark:focus, a.badge-dark.focus {
    box-shadow: 0 0 0 0.1875rem rgba(33, 37, 41, 0.5);
    outline: 0; }

.badge-info {
  background-color: #17A2B8;
  color: #FFF; }
  a.badge-info:hover {
    background-color: #117a8b;
    color: #FFF; }
  a.badge-info:focus, a.badge-info.focus {
    box-shadow: 0 0 0 0.1875rem rgba(23, 162, 184, 0.5);
    outline: 0; }

.badge-light {
  background-color: #F8F9FA;
  color: #212529; }
  a.badge-light:hover {
    background-color: #dae0e5;
    color: #212529; }
  a.badge-light:focus, a.badge-light.focus {
    box-shadow: 0 0 0 0.1875rem rgba(248, 249, 250, 0.5);
    outline: 0; }

.badge-primary {
  background-color: #EE7F01;
  color: #FFF; }
  a.badge-primary:hover {
    background-color: #bb6401;
    color: #FFF; }
  a.badge-primary:focus, a.badge-primary.focus {
    box-shadow: 0 0 0 0.1875rem rgba(238, 127, 1, 0.5);
    outline: 0; }

.badge-secondary {
  background-color: #90AD25;
  color: #FFF; }
  a.badge-secondary:hover {
    background-color: #6d831c;
    color: #FFF; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    box-shadow: 0 0 0 0.1875rem rgba(144, 173, 37, 0.5);
    outline: 0; }

.badge-tertiary {
  background-color: #006893;
  color: #FFF; }
  a.badge-tertiary:hover {
    background-color: #004460;
    color: #FFF; }
  a.badge-tertiary:focus, a.badge-tertiary.focus {
    box-shadow: 0 0 0 0.1875rem rgba(0, 104, 147, 0.5);
    outline: 0; }

.badge-success {
  background-color: #90AD25;
  color: #FFF; }
  a.badge-success:hover {
    background-color: #6d831c;
    color: #FFF; }
  a.badge-success:focus, a.badge-success.focus {
    box-shadow: 0 0 0 0.1875rem rgba(144, 173, 37, 0.5);
    outline: 0; }

.badge-warning {
  background-color: #FFC107;
  color: #212529; }
  a.badge-warning:hover {
    background-color: #d39e00;
    color: #212529; }
  a.badge-warning:focus, a.badge-warning.focus {
    box-shadow: 0 0 0 0.1875rem rgba(255, 193, 7, 0.5);
    outline: 0; }

.badge-white {
  background-color: #FFF;
  color: #212529; }
  a.badge-white:hover {
    background-color: #e6e6e6;
    color: #212529; }
  a.badge-white:focus, a.badge-white.focus {
    box-shadow: 0 0 0 0.1875rem rgba(255, 255, 255, 0.5);
    outline: 0; }

/* -----------------------------------------------------------------------------
  BASE STYLES
*/
.btn {
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
  color: #212529;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.375rem 0.625rem;
  text-align: center;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap; }
  .btn.disabled, .btn:disabled {
    box-shadow: none;
    opacity: 0.65;
    pointer-events: none; }
  .btn:focus, .btn.focus {
    outline: 0; }
  .btn:hover {
    color: #212529;
    text-decoration: none; }

.btn-lg {
  font-size: 1.125rem;
  line-height: 1.8;
  padding: 0.375rem 0.625rem; }

.btn-sm {
  font-size: 0.875rem;
  line-height: 1.2;
  padding: 0.375rem 0.625rem; }

/* -----------------------------------------------------------------------------
  ALTERNATE BUTTONS
*/
.btn-black {
  background-color: #000;
  border-color: #000;
  color: #FFF;
  fill: #FFF; }
  .btn-black:focus, .btn-black.focus {
    background-color: black;
    border-color: black;
    box-shadow: 0 0 0 0.1875rem rgba(0, 0, 0, 0.5);
    color: #FFF;
    fill: #FFF; }
  .btn-black:hover {
    background-color: black;
    border-color: black;
    color: #FFF;
    fill: #FFF; }
  .btn-black.disabled, .btn-black:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-black.disabled:focus, .btn-black.disabled.focus, .btn-black:disabled:focus, .btn-black:disabled.focus {
      background-color: #CED4DA;
      border-color: black;
      box-shadow: 0 0 0 0.1875rem rgba(0, 0, 0, 0.5);
      color: #212529;
      fill: #212529; }

.btn-gray-200 {
  background-color: #E9ECEF;
  border-color: #E9ECEF;
  color: #212529;
  fill: #212529; }
  .btn-gray-200:focus, .btn-gray-200.focus {
    background-color: #dadfe4;
    border-color: #cbd3da;
    box-shadow: 0 0 0 0.1875rem rgba(203, 211, 218, 0.5);
    color: #212529;
    fill: #212529; }
  .btn-gray-200:hover {
    background-color: #dadfe4;
    border-color: #cbd3da;
    color: #212529;
    fill: #212529; }
  .btn-gray-200.disabled, .btn-gray-200:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-gray-200.disabled:focus, .btn-gray-200.disabled.focus, .btn-gray-200:disabled:focus, .btn-gray-200:disabled.focus {
      background-color: #CED4DA;
      border-color: #cbd3da;
      box-shadow: 0 0 0 0.1875rem rgba(203, 211, 218, 0.5);
      color: #212529;
      fill: #212529; }

.btn-gray-300 {
  background-color: #DEE2E6;
  border-color: #DEE2E6;
  color: #212529;
  fill: #212529; }
  .btn-gray-300:focus, .btn-gray-300.focus {
    background-color: #cfd5db;
    border-color: #c1c9d0;
    box-shadow: 0 0 0 0.1875rem rgba(193, 201, 208, 0.5);
    color: #212529;
    fill: #212529; }
  .btn-gray-300:hover {
    background-color: #cfd5db;
    border-color: #c1c9d0;
    color: #212529;
    fill: #212529; }
  .btn-gray-300.disabled, .btn-gray-300:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-gray-300.disabled:focus, .btn-gray-300.disabled.focus, .btn-gray-300:disabled:focus, .btn-gray-300:disabled.focus {
      background-color: #CED4DA;
      border-color: #c1c9d0;
      box-shadow: 0 0 0 0.1875rem rgba(193, 201, 208, 0.5);
      color: #212529;
      fill: #212529; }

.btn-gray-400 {
  background-color: #CED4DA;
  border-color: #CED4DA;
  color: #212529;
  fill: #212529; }
  .btn-gray-400:focus, .btn-gray-400.focus {
    background-color: #bfc7cf;
    border-color: #b1bbc4;
    box-shadow: 0 0 0 0.1875rem rgba(177, 187, 196, 0.5);
    color: #212529;
    fill: #212529; }
  .btn-gray-400:hover {
    background-color: #bfc7cf;
    border-color: #b1bbc4;
    color: #212529;
    fill: #212529; }
  .btn-gray-400.disabled, .btn-gray-400:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-gray-400.disabled:focus, .btn-gray-400.disabled.focus, .btn-gray-400:disabled:focus, .btn-gray-400:disabled.focus {
      background-color: #CED4DA;
      border-color: #b1bbc4;
      box-shadow: 0 0 0 0.1875rem rgba(177, 187, 196, 0.5);
      color: #212529;
      fill: #212529; }

.btn-danger {
  background-color: #DC3545;
  border-color: #DC3545;
  color: #FFF;
  fill: #FFF; }
  .btn-danger:focus, .btn-danger.focus {
    background-color: #d32535;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.1875rem rgba(189, 33, 48, 0.5);
    color: #FFF;
    fill: #FFF; }
  .btn-danger:hover {
    background-color: #d32535;
    border-color: #bd2130;
    color: #FFF;
    fill: #FFF; }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger:disabled:focus, .btn-danger:disabled.focus {
      background-color: #CED4DA;
      border-color: #bd2130;
      box-shadow: 0 0 0 0.1875rem rgba(189, 33, 48, 0.5);
      color: #212529;
      fill: #212529; }

.btn-dark {
  background-color: #212529;
  border-color: #212529;
  color: #FFF;
  fill: #FFF; }
  .btn-dark:focus, .btn-dark.focus {
    background-color: #16181b;
    border-color: #0a0c0d;
    box-shadow: 0 0 0 0.1875rem rgba(10, 12, 13, 0.5);
    color: #FFF;
    fill: #FFF; }
  .btn-dark:hover {
    background-color: #16181b;
    border-color: #0a0c0d;
    color: #FFF;
    fill: #FFF; }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-dark.disabled:focus, .btn-dark.disabled.focus, .btn-dark:disabled:focus, .btn-dark:disabled.focus {
      background-color: #CED4DA;
      border-color: #0a0c0d;
      box-shadow: 0 0 0 0.1875rem rgba(10, 12, 13, 0.5);
      color: #212529;
      fill: #212529; }

.btn-info {
  background-color: #17A2B8;
  border-color: #17A2B8;
  color: #FFF;
  fill: #FFF; }
  .btn-info:focus, .btn-info.focus {
    background-color: #148ea1;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.1875rem rgba(17, 122, 139, 0.5);
    color: #FFF;
    fill: #FFF; }
  .btn-info:hover {
    background-color: #148ea1;
    border-color: #117a8b;
    color: #FFF;
    fill: #FFF; }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info:disabled:focus, .btn-info:disabled.focus {
      background-color: #CED4DA;
      border-color: #117a8b;
      box-shadow: 0 0 0 0.1875rem rgba(17, 122, 139, 0.5);
      color: #212529;
      fill: #212529; }

.btn-light {
  background-color: #F8F9FA;
  border-color: #F8F9FA;
  color: #212529;
  fill: #212529; }
  .btn-light:focus, .btn-light.focus {
    background-color: #e9ecef;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.1875rem rgba(218, 224, 229, 0.5);
    color: #212529;
    fill: #212529; }
  .btn-light:hover {
    background-color: #e9ecef;
    border-color: #dae0e5;
    color: #212529;
    fill: #212529; }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-light.disabled:focus, .btn-light.disabled.focus, .btn-light:disabled:focus, .btn-light:disabled.focus {
      background-color: #CED4DA;
      border-color: #dae0e5;
      box-shadow: 0 0 0 0.1875rem rgba(218, 224, 229, 0.5);
      color: #212529;
      fill: #212529; }

.btn-primary {
  background-color: #EE7F01;
  border-color: #EE7F01;
  color: #FFF;
  fill: #FFF; }
  .btn-primary:focus, .btn-primary.focus {
    background-color: #d57101;
    border-color: #bb6401;
    box-shadow: 0 0 0 0.1875rem rgba(187, 100, 1, 0.5);
    color: #FFF;
    fill: #FFF; }
  .btn-primary:hover {
    background-color: #d57101;
    border-color: #bb6401;
    color: #FFF;
    fill: #FFF; }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary:disabled:focus, .btn-primary:disabled.focus {
      background-color: #CED4DA;
      border-color: #bb6401;
      box-shadow: 0 0 0 0.1875rem rgba(187, 100, 1, 0.5);
      color: #212529;
      fill: #212529; }

.btn-secondary {
  background-color: #90AD25;
  border-color: #90AD25;
  color: #FFF;
  fill: #FFF; }
  .btn-secondary:focus, .btn-secondary.focus {
    background-color: #7f9821;
    border-color: #6d831c;
    box-shadow: 0 0 0 0.1875rem rgba(109, 131, 28, 0.5);
    color: #FFF;
    fill: #FFF; }
  .btn-secondary:hover {
    background-color: #7f9821;
    border-color: #6d831c;
    color: #FFF;
    fill: #FFF; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-secondary.disabled:focus, .btn-secondary.disabled.focus, .btn-secondary:disabled:focus, .btn-secondary:disabled.focus {
      background-color: #CED4DA;
      border-color: #6d831c;
      box-shadow: 0 0 0 0.1875rem rgba(109, 131, 28, 0.5);
      color: #212529;
      fill: #212529; }

.btn-tertiary {
  background-color: #006893;
  border-color: #006893;
  color: #FFF;
  fill: #FFF; }
  .btn-tertiary:focus, .btn-tertiary.focus {
    background-color: #00567a;
    border-color: #004460;
    box-shadow: 0 0 0 0.1875rem rgba(0, 68, 96, 0.5);
    color: #FFF;
    fill: #FFF; }
  .btn-tertiary:hover {
    background-color: #00567a;
    border-color: #004460;
    color: #FFF;
    fill: #FFF; }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-tertiary.disabled:focus, .btn-tertiary.disabled.focus, .btn-tertiary:disabled:focus, .btn-tertiary:disabled.focus {
      background-color: #CED4DA;
      border-color: #004460;
      box-shadow: 0 0 0 0.1875rem rgba(0, 68, 96, 0.5);
      color: #212529;
      fill: #212529; }

.btn-success {
  background-color: #90AD25;
  border-color: #90AD25;
  color: #FFF;
  fill: #FFF; }
  .btn-success:focus, .btn-success.focus {
    background-color: #7f9821;
    border-color: #6d831c;
    box-shadow: 0 0 0 0.1875rem rgba(109, 131, 28, 0.5);
    color: #FFF;
    fill: #FFF; }
  .btn-success:hover {
    background-color: #7f9821;
    border-color: #6d831c;
    color: #FFF;
    fill: #FFF; }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success:disabled:focus, .btn-success:disabled.focus {
      background-color: #CED4DA;
      border-color: #6d831c;
      box-shadow: 0 0 0 0.1875rem rgba(109, 131, 28, 0.5);
      color: #212529;
      fill: #212529; }

.btn-warning {
  background-color: #FFC107;
  border-color: #FFC107;
  color: #212529;
  fill: #212529; }
  .btn-warning:focus, .btn-warning.focus {
    background-color: #edb100;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.1875rem rgba(211, 158, 0, 0.5);
    color: #212529;
    fill: #212529; }
  .btn-warning:hover {
    background-color: #edb100;
    border-color: #d39e00;
    color: #212529;
    fill: #212529; }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning:disabled:focus, .btn-warning:disabled.focus {
      background-color: #CED4DA;
      border-color: #d39e00;
      box-shadow: 0 0 0 0.1875rem rgba(211, 158, 0, 0.5);
      color: #212529;
      fill: #212529; }

.btn-white {
  background-color: #FFF;
  border-color: #FFF;
  color: #212529;
  fill: #212529; }
  .btn-white:focus, .btn-white.focus {
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.1875rem rgba(230, 230, 230, 0.5);
    color: #212529;
    fill: #212529; }
  .btn-white:hover {
    background-color: #f2f2f2;
    border-color: #e6e6e6;
    color: #212529;
    fill: #212529; }
  .btn-white.disabled, .btn-white:disabled {
    background-color: #CED4DA;
    border-color: #CED4DA;
    color: #212529;
    fill: #212529; }
    .btn-white.disabled:focus, .btn-white.disabled.focus, .btn-white:disabled:focus, .btn-white:disabled.focus {
      background-color: #CED4DA;
      border-color: #e6e6e6;
      box-shadow: 0 0 0 0.1875rem rgba(230, 230, 230, 0.5);
      color: #212529;
      fill: #212529; }

/* -----------------------------------------------------------------------------
  LOADER
*/
@keyframes btn-loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.btn-loader {
  color: transparent !important;
  cursor: default;
  fill: transparent !important;
  pointer-events: none;
  position: relative; }
  .btn-loader:after {
    animation: btn-loader 300ms linear infinite;
    border: 0.125rem solid rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    border-top-color: #FFF;
    content: "";
    display: block;
    height: 1.5rem;
    left: 50%;
    margin-left: -0.75rem;
    margin-top: -0.75rem;
    position: absolute;
    top: 50%;
    transition: opacity;
    width: 1.5rem; }

/* -----------------------------------------------------------------------------
  BUTTON GROUP
*/
.btn-group {
  display: inline-flex;
  position: relative;
  vertical-align: middle; }
  .btn-group > .btn {
    flex: 1 1 auto;
    position: relative; }
    .btn-group > .btn:active, .btn-group > .btn.active, .btn-group > .btn:focus, .btn-group > .btn:hover {
      z-index: 1; }
  .btn-group > .btn:not(:first-child) {
    margin-left: -0.0625rem; }
  .btn-group > .btn:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .btn-group > .btn:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

/* -----------------------------------------------------------------------------
  CARD
*/
.card {
  background-clip: border-box;
  background-color: #FFF;
  border: 0.0625rem solid rgba(0, 0, 0, 0.125);
  border-radius: 0.3125rem;
  display: flex;
  flex-direction: column;
  min-width: 0;
  position: relative;
  word-wrap: break-word; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-radius: 0.3125rem 0.3125rem 0 0; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-radius: 0 0 0.3125rem 0.3125rem; }

.card-header {
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.125);
  margin-bottom: 0;
  padding: 0.5rem 1rem; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 0.5rem 1rem; }

.card-title {
  margin-bottom: 0.5rem; }

.card-footer {
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 0 0 0.3125rem 0.3125rem;
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.125);
  padding: 0.5rem 1rem; }

/* -----------------------------------------------------------------------------
  ACCORDION
*/
.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-radius: 0.3125rem 0.3125rem 0 0; }
  .accordion > .card:last-of-type {
    border-radius: 0 0 0.3125rem 0.3125rem; }
  .accordion > .card .card-header {
    margin-bottom: -0.0625rem !important; }

/* -----------------------------------------------------------------------------
  COLUMNS
*/
.column-1 {
  column-count: 1;
  column-gap: 2.5rem; }

.column-2 {
  column-count: 2;
  column-gap: 2.5rem; }

.column-3 {
  column-count: 3;
  column-gap: 2.5rem; }

.column-4 {
  column-count: 4;
  column-gap: 2.5rem; }

.column-5 {
  column-count: 5;
  column-gap: 2.5rem; }

.column-6 {
  column-count: 6;
  column-gap: 2.5rem; }

@media (min-width: 30rem) {
  .column-sm-1 {
    column-count: 1;
    column-gap: 2.5rem; }
  .column-sm-2 {
    column-count: 2;
    column-gap: 2.5rem; }
  .column-sm-3 {
    column-count: 3;
    column-gap: 2.5rem; }
  .column-sm-4 {
    column-count: 4;
    column-gap: 2.5rem; }
  .column-sm-5 {
    column-count: 5;
    column-gap: 2.5rem; }
  .column-sm-6 {
    column-count: 6;
    column-gap: 2.5rem; } }

@media (min-width: 48rem) {
  .column-md-1 {
    column-count: 1;
    column-gap: 2.5rem; }
  .column-md-2 {
    column-count: 2;
    column-gap: 2.5rem; }
  .column-md-3 {
    column-count: 3;
    column-gap: 2.5rem; }
  .column-md-4 {
    column-count: 4;
    column-gap: 2.5rem; }
  .column-md-5 {
    column-count: 5;
    column-gap: 2.5rem; }
  .column-md-6 {
    column-count: 6;
    column-gap: 2.5rem; } }

@media (min-width: 62rem) {
  .column-lg-1 {
    column-count: 1;
    column-gap: 2.5rem; }
  .column-lg-2 {
    column-count: 2;
    column-gap: 2.5rem; }
  .column-lg-3 {
    column-count: 3;
    column-gap: 2.5rem; }
  .column-lg-4 {
    column-count: 4;
    column-gap: 2.5rem; }
  .column-lg-5 {
    column-count: 5;
    column-gap: 2.5rem; }
  .column-lg-6 {
    column-count: 6;
    column-gap: 2.5rem; } }

@media (min-width: 87.5rem) {
  .column-xl-1 {
    column-count: 1;
    column-gap: 2.5rem; }
  .column-xl-2 {
    column-count: 2;
    column-gap: 2.5rem; }
  .column-xl-3 {
    column-count: 3;
    column-gap: 2.5rem; }
  .column-xl-4 {
    column-count: 4;
    column-gap: 2.5rem; }
  .column-xl-5 {
    column-count: 5;
    column-gap: 2.5rem; }
  .column-xl-6 {
    column-count: 6;
    column-gap: 2.5rem; } }

/* -----------------------------------------------------------------------------
  DROPDOWN
*/
.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle:after {
    content: "";
    display: inline-block;
    margin-left: 0.375rem;
    vertical-align: 0.25rem;
    border-bottom: 0;
    border-left: 0.25rem solid transparent;
    border-right: 0.25rem solid transparent;
    border-top: 0.25rem solid; }
  .dropdown-toggle:empty:after {
    margin-left: 0; }

.dropdown-menu {
  background-color: #FFF;
  border: 0.0625rem solid #CED4DA;
  border-radius: 0.3125rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.175);
  color: #212529;
  display: none;
  float: left;
  left: 0;
  list-style: none;
  margin: 0.125rem 0 0;
  min-width: 10rem;
  padding: 0.5rem 0;
  position: absolute;
  text-align: left;
  top: 100%;
  z-index: 1000; }

.dropdown-menu-left {
  left: 0;
  right: auto; }

.dropdown-menu-right {
  left: auto;
  right: 0; }

@media (min-width: 30rem) {
  .dropdown-menu-sm-left {
    left: 0;
    right: auto; }
  .dropdown-menu-sm-right {
    left: auto;
    right: 0; } }

@media (min-width: 48rem) {
  .dropdown-menu-md-left {
    left: 0;
    right: auto; }
  .dropdown-menu-md-right {
    left: auto;
    right: 0; } }

@media (min-width: 62rem) {
  .dropdown-menu-lg-left {
    left: 0;
    right: auto; }
  .dropdown-menu-lg-right {
    left: auto;
    right: 0; } }

@media (min-width: 87.5rem) {
  .dropdown-menu-xl-left {
    left: 0;
    right: auto; }
  .dropdown-menu-xl-right {
    left: auto;
    right: 0; } }

.dropup .dropdown-menu {
  bottom: 100%;
  margin-bottom: 0.125rem;
  margin-top: 0;
  top: auto; }

.dropup .dropdown-toggle:after {
  content: "";
  display: inline-block;
  margin-left: 0.375rem;
  vertical-align: 0.25rem;
  border-bottom: 0.25rem solid;
  border-left: 0.25rem solid transparent;
  border-right: 0.25rem solid transparent;
  border-top: 0; }

.dropup .dropdown-toggle:empty:after {
  margin-left: 0; }

.dropright .dropdown-menu {
  left: 100%;
  margin-left: 0.125rem;
  margin-top: 0;
  right: auto;
  top: 0; }

.dropright .dropdown-toggle:after {
  content: "";
  display: inline-block;
  margin-left: 0.375rem;
  vertical-align: 0.25rem;
  border-bottom: 0.25rem solid transparent;
  border-left: 0.25rem solid;
  border-right: 0;
  border-top: 0.25rem solid transparent; }

.dropright .dropdown-toggle:empty:after {
  margin-left: 0; }

.dropright .dropdown-toggle:after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  left: auto;
  margin-right: 0.125rem;
  margin-top: 0;
  right: 100%;
  top: 0; }

.dropleft .dropdown-toggle:after {
  content: "";
  display: inline-block;
  margin-left: 0.375rem;
  vertical-align: 0.25rem; }

.dropleft .dropdown-toggle:after {
  display: none; }

.dropleft .dropdown-toggle:before {
  content: "";
  display: inline-block;
  margin-right: 0.25rem;
  vertical-align: 0.25rem;
  border-bottom: 0.25rem solid transparent;
  border-right: 0.25rem solid;
  border-top: 0.25rem solid transparent; }

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0; }

.dropleft .dropdown-toggle:before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  bottom: auto;
  right: auto; }

.dropdown-divider {
  border-top: 0.0625rem solid #E9ECEF;
  height: 0;
  margin: 0.25rem 0;
  overflow: hidden; }

.dropdown-item {
  background-color: transparent;
  border: 0;
  clear: both;
  color: #212529;
  display: block;
  padding: 0.25rem 1.5rem;
  text-align: inherit;
  white-space: nowrap;
  width: 100%; }
  .dropdown-item:focus, .dropdown-item:hover {
    background-color: #FFF;
    color: #006893;
    text-decoration: none; }
  .dropdown-item.active, .dropdown-item:active {
    background-color: #FFF;
    color: #212529;
    text-decoration: none; }
    .dropdown-item.active:after, .dropdown-item:active:after {
      border: 0 !important; }
    .dropdown-item.active:focus, .dropdown-item.active:hover, .dropdown-item:active:focus, .dropdown-item:active:hover {
      background-color: #FFF;
      color: #006893;
      text-decoration: none; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    background-color: transparent;
    color: #868E96;
    pointer-events: none; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  color: #868E96;
  display: block;
  margin-bottom: 0;
  padding: 8 24;
  white-space: nowrap; }

.dropdown-item-text {
  color: #212529;
  display: block;
  padding: 4 24; }

/* -----------------------------------------------------------------------------
  GENERAL
*/
.custom-control {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  position: relative; }

.custom-control-label {
  margin-bottom: 0;
  margin-top: calc(0.375rem + 0.0625rem);
  position: relative;
  vertical-align: top; }
  .custom-control-label:before {
    background-color: #FFF;
    border: 0.0625rem solid #ADB5BD;
    content: "";
    display: block;
    height: 1rem;
    left: -1.5rem;
    pointer-events: none;
    position: absolute;
    top: 0.16667rem;
    transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
    width: 1rem; }
  .custom-control-label:after {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 50%;
    content: "";
    display: block;
    height: 1rem;
    left: -1.5rem;
    position: absolute;
    top: 0.16667rem;
    width: 1rem; }

.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1; }
  .custom-control-input:checked ~ .custom-control-label:before {
    background: #EE7F01;
    border-color: #EE7F01; }
  .custom-control-input:focus ~ .custom-control-label:before {
    border-color: #EE7F01;
    box-shadow: 0 0 0 0.1875rem rgba(238, 127, 1, 0.5); }
  .custom-control-input[disabled] ~ .custom-control-label {
    color: #868E96;
    pointer-events: none; }
    .custom-control-input[disabled] ~ .custom-control-label:before {
      background-color: #E9ECEF;
      border-color: #868E96; }
  .custom-control-input:checked[disabled] ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23868E96' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e") !important; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

/* -----------------------------------------------------------------------------
  CHECKBOX
*/
.custom-checkbox .custom-control-label:before {
  border-radius: 0.3125rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

/* -----------------------------------------------------------------------------
  RADIO
*/
.custom-radio .custom-control-label:before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e"); }

/* -----------------------------------------------------------------------------
  HELPERS
*/
.custom-select, .custom-date {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343A40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.5rem 0.625rem; }

/* -----------------------------------------------------------------------------
  INPUT
*/
.form-control {
  background-clip: padding-box;
  background-color: #FFF;
  border: 0.0625rem solid #ADB5BD;
  border-radius: 0.3125rem;
  color: #495057;
  display: block;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 0.125rem);
  line-height: 1.5;
  padding: 0.375rem 0.75rem;
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
  width: 100%; }
  .form-control:focus {
    background-color: #FFF;
    border-color: #EE7F01;
    box-shadow: 0 0 0 0.1875rem rgba(238, 127, 1, 0.5);
    color: #495057;
    outline: 0; }
  .form-control:disabled {
    cursor: not-allowed; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #E9ECEF;
    opacity: 1; }
  .form-control::placeholder {
    color: #868E96;
    opacity: 1; }

.form-control-plaintext {
  background-color: transparent;
  border: solid transparent;
  color: #212529;
  display: block;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 0.125rem);
  line-height: 1.5;
  line-height: 1.5;
  margin-bottom: 0;
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
  width: 100%; }

/* -----------------------------------------------------------------------------
  SELECT
*/
.custom-select {
  appearance: none;
  background-color: #FFF;
  border: 0.0625rem solid #ADB5BD;
  border-radius: 0.3125rem;
  color: #495057;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 0.125rem);
  line-height: 1.5;
  margin: 0;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  text-transform: none;
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
  vertical-align: middle;
  width: 100%;
  word-wrap: normal; }
  .custom-select:focus {
    background-color: #FFF;
    border-color: #EE7F01;
    box-shadow: 0 0 0 0.1875rem rgba(238, 127, 1, 0.5);
    color: #495057;
    outline: 0; }
    .custom-select:focus::-ms-value {
      background-color: #FFF;
      color: #495057; }
  .custom-select:disabled {
    background-color: #E9ECEF;
    cursor: not-allowed; }
  .custom-select[readonly] {
    background-color: #E9ECEF;
    pointer-events: none; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    background-image: none;
    height: auto;
    padding-right: 0.75rem; }
    .custom-select[multiple] option, .custom-select[size]:not([size="1"]) option {
      margin: 0.25rem 0; }
  .custom-select::-ms-expand {
    display: none; }

optgroup {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0; }

/* -----------------------------------------------------------------------------
  TINY MCE
*/
.mce-tinymce {
  border: 0.0625rem solid #ADB5BD !important;
  border-radius: 0.3125rem;
  overflow: hidden;
  transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms; }
  .mce-tinymce.focus {
    border-color: #EE7F01 !important;
    box-shadow: 0 0 0 0.1875rem rgba(238, 127, 1, 0.5); }
  .mce-tinymce.is-invalid {
    border-color: #DC3545 !important; }
    .mce-tinymce.is-invalid.focus {
      border-color: #DC3545 !important;
      box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25); }
    .mce-tinymce.is-invalid ~ .invalid-feedback {
      display: block; }

/* -----------------------------------------------------------------------------
  INLINE FORMS
*/
.form-inline {
  align-items: center;
  display: flex;
  flex-flow: row nowrap; }
  .form-inline label {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 0.5rem; }
  .form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
    width: auto; }
  .form-inline .custom-select {
    width: auto; }
  .form-inline .custom-date {
    min-width: inherit; }

/* -----------------------------------------------------------------------------
  FIELDSET
*/
fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0; }

legend {
  font-size: 0.875rem;
  color: inherit;
  cursor: default !important;
  display: block;
  line-height: inherit;
  margin-bottom: 0.5rem;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%; }
  @media (min-width: 30rem) {
    legend {
      font-size: calc(0.875rem + 0.125 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    legend {
      font-size: 1rem; } }
  legend.label {
    margin-bottom: 0; }

/* -----------------------------------------------------------------------------
  FILE
*/
.custom-file {
  display: inline-block;
  height: calc(1.5em + 0.75rem + 0.125rem);
  margin-bottom: 0;
  position: relative;
  width: 100%; }

.custom-file-input {
  cursor: pointer;
  height: calc(1.5em + 0.75rem + 0.125rem);
  margin: 0;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 2; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #EE7F01;
    box-shadow: 0 0 0 0.1875rem rgba(238, 127, 1, 0.5); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #E9ECEF; }
  .custom-file-input:lang(de) ~ .custom-file-label:after {
    content: "Durchsuchen"; }
  .custom-file-input:lang(en) ~ .custom-file-label:after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]:after {
    content: attr(data-browse); }

.custom-file-label {
  background-color: #FFF;
  border: 0.0625rem solid #ADB5BD;
  border-radius: 0.3125rem;
  color: #495057;
  font-size: 1rem;
  height: calc(1.5em + 0.75rem + 0.125rem);
  left: 0;
  line-height: 1.5;
  overflow: hidden;
  padding: 0.375rem 0.75rem;
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
  z-index: 1; }
  .custom-file-label:after {
    background: #E9ECEF;
    border-left: inherit;
    border-radius: 0 0.3125rem 0.3125rem 0;
    bottom: 0;
    color: #495057;
    display: block;
    height: calc(1.5em + 0.75rem);
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3; }

/* -----------------------------------------------------------------------------
  INPUT GROUP
*/
.input-group {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%; }
  .input-group > .form-control {
    flex: 1 1 0%;
    margin-bottom: 0;
    min-width: 0;
    position: relative; }
  .input-group > .form-control:focus {
    z-index: 3; }
  .input-group > .form-control:not(:last-child) {
    border-radius: 0.3125rem 0 0 0.3125rem; }
  .input-group > .form-control:not(:first-child) {
    border-radius: 0 0.3125rem 0.3125rem 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }

.input-group-prepend {
  margin-right: -0.0625rem; }

.input-group-append {
  margin-left: -0.0625rem; }

.input-group-text {
  align-items: center;
  background-color: #E9ECEF;
  border: 0.0625rem solid #ADB5BD;
  border-radius: 0.3125rem;
  color: #495057;
  display: flex;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  text-align: center;
  white-space: nowrap; }

.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-radius: 0.3125rem 0 0 0.3125rem; }

.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-radius: 0 0.3125rem 0.3125rem 0; }

/* -----------------------------------------------------------------------------
  LABELS
*/
label,
.label {
  font-size: 0.875rem;
  cursor: pointer;
  display: inline-block;
  line-height: 1.5;
  margin-bottom: calc(0.375rem + 0.0625rem); }
  @media (min-width: 30rem) {
    label,
    .label {
      font-size: calc(0.875rem + 0.0625 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    label,
    .label {
      font-size: 0.9375rem; } }

/* -----------------------------------------------------------------------------
  RESETS
*/
input {
  border-radius: 0;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: visible; }
  input[type="search"] {
    appearance: none;
    outline-offset: -0.125rem; }
    input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
      -webkit-appearance: none; }
  input[type="date"], input[type="datetime-local"], input[type="time"], input[type="month"] {
    cursor: text;
    min-width: 100%; }
  input::-webkit-contacts-auto-fill-button, input::-webkit-credentials-auto-fill-button {
    pointer-events: none;
    visibility: hidden; }
  input::-webkit-clear-button, input::-ms-clear {
    display: none; }

textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: auto;
  resize: vertical; }
  textarea[disabled] {
    cursor: not-allowed; }
  textarea.form-control {
    height: auto; }

button {
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  overflow: hidden;
  text-transform: none;
  user-select: none; }
  button:focus {
    outline: 0.0625rem dotted; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button,
[type="reset"],
[type="submit"] {
  appearance: button; }

/* -----------------------------------------------------------------------------
  INPUTS
*/
.form-group {
  margin-bottom: 1rem; }

.custom-select, .custom-date {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343A40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center;
  background-repeat: no-repeat;
  background-size: 0.5rem 0.625rem; }

.form-control-sm {
  font-size: 0.875rem;
  height: 2rem;
  padding: 0.125rem 0.375rem; }

.custom-date::-webkit-inner-spin-button, .custom-date::-webkit-outer-spin-button {
  visibility: hidden; }

.custom-date::-webkit-calendar-picker-indicator {
  cursor: pointer;
  margin: 0;
  opacity: 0;
  width: 0.625rem; }

.custom-number {
  -moz-appearance: textfield; }

.custom-number::-webkit-inner-spin-button, .custom-number::-webkit-outer-spin-button,
.custom-time::-webkit-inner-spin-button,
.custom-time::-webkit-outer-spin-button {
  display: none; }

.custom-select-sm {
  background-position: right 0.5rem center;
  font-size: 0.875rem;
  height: 2rem;
  line-height: 1.625rem;
  padding: 0.125rem 1.375rem 0.125rem 0.375rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }
  .form-text ~ .invalid-feedback {
    margin: 0; }

/* -----------------------------------------------------------------------------
  VALIDATION
*/
.errorlist {
  margin-bottom: 0; }

.invalid-feedback {
  color: #DC3545;
  display: none;
  margin-top: 0.25rem;
  width: 100%; }
  .invalid-feedback:hover {
    color: #DC3545;
    text-decoration: none; }

.form-control.is-invalid,
.custom-select.is-invalid,
.input-wrapper.is-invalid,
.form-set-label.is-invalid {
  border-color: #DC3545; }
  .form-control.is-invalid:focus,
  .custom-select.is-invalid:focus,
  .input-wrapper.is-invalid:focus,
  .form-set-label.is-invalid:focus {
    border-color: #DC3545;
    box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25); }
  .form-control.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-feedback,
  .input-wrapper.is-invalid ~ .invalid-feedback,
  .form-set-label.is-invalid ~ .invalid-feedback {
    display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #DC3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label:before, .custom-control-input.is-invalid ~ .custom-control-label:before {
    border-color: #DC3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-feedback {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before, .custom-control-input.is-invalid:checked ~ .custom-control-label:before {
  border-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before, .custom-control-input.is-invalid:focus ~ .custom-control-label:before {
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before {
  border-color: #DC3545; }

.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DC3545; }

.custom-file-input.is-invalid ~ .invalid-feedback {
  display: block; }

.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #DC3545;
  box-shadow: 0 0 0 0.1875rem rgba(220, 53, 69, 0.25); }

/* -----------------------------------------------------------------------------
  RANGE
*/
.custom-range {
  appearance: none;
  background-color: transparent;
  height: calc(1rem + 0.125rem);
  padding: 0;
  width: 100%; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb, .custom-range:focus::-moz-range-thumb, .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 0.0625rem #FFF, 0 0 0 0.1875rem rgba(238, 127, 1, 0.5); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    appearance: none;
    background-color: #EE7F01;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
    width: 1rem; }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #ffd4a3; }
  .custom-range::-webkit-slider-runnable-track {
    background-color: #CED4DA;
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: 0.5rem;
    width: 0.5rem; }
  .custom-range::-moz-range-thumb {
    appearance: none;
    background-color: #EE7F01;
    border: 0;
    border-radius: 1rem;
    height: 1rem;
    transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
    width: 1rem; }
    .custom-range::-moz-range-thumb:active {
      background-color: #ffd4a3; }
  .custom-range::-moz-range-track {
    background-color: #CED4DA;
    border-color: transparent;
    border-radius: 1rem;
    color: transparent;
    cursor: pointer;
    height: 0.5rem;
    width: 100%; }
  .custom-range::-ms-thumb {
    appearance: none;
    background-color: #EE7F01;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    height: 1rem;
    margin-left: 0.0625rem;
    margin-right: 0.0625rem;
    margin-top: 0;
    transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
    width: 1rem; }
    .custom-range::-ms-thumb:active {
      background-color: #ffd4a3; }
  .custom-range::-ms-track {
    background-color: transparent;
    border-color: transparent;
    border-radius: 1rem;
    border-width: 0.5rem;
    color: transparent;
    cursor: pointer;
    height: 0.5rem;
    width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #CED4DA;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    background-color: #CED4DA;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #ADB5BD; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #ADB5BD; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #ADB5BD; }

/* -----------------------------------------------------------------------------
  CONTAINER
*/
.container-fluid {
  margin: 0 auto;
  min-width: 20rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%; }
  @media (min-width: 30rem) {
    .container-fluid {
      padding-left: calc(0.9375rem + 0.9375 * (100vw - 30rem) / (87.5 - 30));
      padding-right: calc(0.9375rem + 0.9375 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .container-fluid {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }

.container-max-fluid {
  margin: 0 auto;
  min-width: 20rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  max-width: 121.25rem; }
  @media (min-width: 30rem) {
    .container-max-fluid {
      padding-left: calc(0.9375rem + 0.9375 * (100vw - 30rem) / (87.5 - 30));
      padding-right: calc(0.9375rem + 0.9375 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .container-max-fluid {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }

.container {
  margin: 0 auto;
  min-width: 20rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 100%;
  max-width: 0; }
  @media (min-width: 30rem) {
    .container {
      padding-left: calc(0.9375rem + 0.9375 * (100vw - 30rem) / (87.5 - 30));
      padding-right: calc(0.9375rem + 0.9375 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .container {
      padding-left: 1.875rem;
      padding-right: 1.875rem; } }
  @media (min-width: 30rem) {
    .container {
      max-width: 30rem; } }
  @media (min-width: 48rem) {
    .container {
      max-width: 48rem; } }
  @media (min-width: 62rem) {
    .container {
      max-width: 62rem; } }
  @media (min-width: 87.5rem) {
    .container {
      max-width: 121.25rem; } }

/* -----------------------------------------------------------------------------
  ROW
*/
.row {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  margin-left: -0.46875rem;
  margin-right: -0.46875rem; }
  @media (min-width: 30rem) {
    .row {
      margin-left: calc(-0.46875rem - 0.46875 * (100vw - 30rem) / (87.5 - 30));
      margin-right: calc(-0.46875rem - 0.46875 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .row {
      margin-left: -0.9375rem;
      margin-right: -0.9375rem; } }
  .row.sm-gutters {
    margin-left: -0.23438rem;
    margin-right: -0.23438rem; }
    @media (min-width: 30rem) {
      .row.sm-gutters {
        margin-left: calc(-0.23438rem - 0.23438 * (100vw - 30rem) / (87.5 - 30));
        margin-right: calc(-0.23438rem - 0.23438 * (100vw - 30rem) / (87.5 - 30)); } }
    @media (min-width: 87.5rem) {
      .row.sm-gutters {
        margin-left: -0.46875rem;
        margin-right: -0.46875rem; } }

.sm-gutters > .col,
.sm-gutters > [class*="col-"] {
  padding-left: 0.23438rem;
  padding-right: 0.23438rem; }
  @media (min-width: 30rem) {
    .sm-gutters > .col,
    .sm-gutters > [class*="col-"] {
      padding-left: calc(0.23438rem + 0.23438 * (100vw - 30rem) / (87.5 - 30));
      padding-right: calc(0.23438rem + 0.23438 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .sm-gutters > .col,
    .sm-gutters > [class*="col-"] {
      padding-left: 0.46875rem;
      padding-right: 0.46875rem; } }

.no-gutters {
  margin-left: 0;
  margin-right: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

/* -----------------------------------------------------------------------------
  COLUMNS
*/
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  padding-left: 0.46875rem;
  padding-right: 0.46875rem;
  position: relative;
  width: 100%; }
  @media (min-width: 30rem) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-left: calc(0.46875rem + 0.46875 * (100vw - 30rem) / (87.5 - 30));
      padding-right: calc(0.46875rem + 0.46875 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
    .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
    .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
    .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
    .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
    .col-xl-auto {
      padding-left: 0.9375rem;
      padding-right: 0.9375rem; } }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  max-width: 100%;
  width: auto; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 30rem) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 48rem) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 62rem) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 87.5rem) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    max-width: 100%;
    width: auto; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

/* -----------------------------------------------------------------------------
  RESETS
*/
svg {
  overflow: hidden;
  vertical-align: middle; }

img {
  border-style: none;
  vertical-align: middle; }

/* -----------------------------------------------------------------------------
  RESPONSIVE
*/
.img-fluid {
  height: auto;
  max-width: 100%; }

/* -----------------------------------------------------------------------------
  LIST GROUPS
*/
.list-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0; }

.list-group-item {
  background-color: #FFF;
  border: 0.0625rem solid #CED4DA;
  display: block;
  margin-bottom: -0.0625rem;
  padding: 0.5rem 1.25rem;
  position: relative; }
  .list-group-item:first-child {
    border-radius: 0.3125rem 0.3125rem 0 0; }
  .list-group-item:last-child {
    border-radius: 0 0 0.3125rem 0.3125rem;
    margin-bottom: 0; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868E96;
    pointer-events: none; }
  .list-group-item.active {
    background-color: #90AD25;
    border-color: #90AD25;
    color: #FFF;
    z-index: 2; }

.list-group-flush .list-group-item {
  border-left: 0;
  border-radius: 0;
  border-right: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -0.0625rem; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0;
  margin-bottom: 0; }

/* -----------------------------------------------------------------------------
  MODAL
*/
.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  display: none;
  height: 100%;
  left: 0;
  min-width: 20rem;
  outline: 0;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 8070; }

.modal-dialog {
  margin: 1rem 0.5rem;
  pointer-events: none;
  position: relative;
  width: auto; }
  .modal.fade .modal-dialog {
    transform: translate(0, -3.125rem);
    transition: transform 300ms ease-out; }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 2rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 2rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  align-items: center;
  display: flex;
  min-height: calc(100% - 2rem); }
  .modal-dialog-centered:before {
    content: "";
    display: block;
    height: calc(100vh - 2rem); }

.modal-sm .modal-content {
  max-width: 18.75rem; }

.modal-md .modal-content {
  max-width: 28.125rem; }

.modal-lg .modal-content {
  max-width: 37.5rem; }

.modal-xl .modal-content {
  max-width: 62.5rem; }

.modal-content {
  background-clip: padding-box;
  background-color: #FFF;
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%; }

.modal-backdrop {
  background-color: #000;
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 8060; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.9; }

@keyframes color-header-animation {
  0% {
    background-position: 22% 0%; }
  100% {
    background-position: 100% 0%; } }

.modal-header {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  position: relative; }
  .modal-header .close {
    margin: -0.375rem -0.5rem -0.5rem auto;
    padding: 0.5rem; }
  .modal-header:after {
    content: "";
    height: 0.4375rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }

.modal-title {
  line-height: 1.5;
  margin-bottom: 0; }

.modal-body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative; }

.modal-footer {
  align-items: center;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top: 0.0625rem solid #CED4DA;
  display: flex;
  justify-content: flex-end;
  padding: 1rem; }
  .modal-footer > :not(:first-child) {
    margin-left: 0.25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: 0.25rem; }

.modal-scrollbar-measure {
  height: 3.125rem;
  overflow: scroll;
  position: absolute;
  top: -624.9375rem;
  width: 3.125rem; }

@media (min-width: 62rem) {
  .modal-lg {
    margin-bottom: 1.75rem;
    margin-top: 1.75rem; }
    .modal-lg.modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
      .modal-lg.modal-dialog-centered:before {
        height: calc(100vh - 3.5rem); } }

@media (min-width: 87.5rem) {
  .modal-xl {
    margin-bottom: 1.75rem;
    margin-top: 1.75rem; }
    .modal-xl.modal-dialog-scrollable {
      max-height: calc(100% - 3.5rem); }
      .modal-xl.modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem); }
    .modal-xl.modal-dialog-centered {
      min-height: calc(100% - 3.5rem); }
      .modal-xl.modal-dialog-centered:before {
        height: calc(100vh - 3.5rem); } }

/* -----------------------------------------------------------------------------
  NAV
*/
.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0; }

.nav-link {
  display: block;
  padding: 0.375rem 0.5rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868E96;
    cursor: default;
    pointer-events: none; }

.nav-pills .nav-link {
  border-radius: 0.3125rem; }

.nav-pills .nav-link.active {
  background-color: #EE7F01;
  color: #FFF;
  fill: #FFF; }

/* -----------------------------------------------------------------------------
  TABS
*/
.nav-tabs {
  border-bottom: 0.0625rem solid #ADB5BD; }
  .nav-tabs .nav-item {
    margin-bottom: -0.0625rem;
    margin-left: 0.125rem;
    margin-right: 0.125rem; }
    .nav-tabs .nav-item:first-child {
      margin-left: 0; }
    .nav-tabs .nav-item:last-child {
      margin-right: 0; }
  .nav-tabs .nav-link {
    border: 0.0625rem solid transparent;
    border-top-left-radius: 0.3125rem;
    border-top-right-radius: 0.3125rem; }
    .nav-tabs .nav-link:hover {
      border-color: #CED4DA #CED4DA #ADB5BD; }
    .nav-tabs .nav-link.disabled {
      background-color: transparent;
      border-color: transparent;
      color: #495057; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    background-color: #FFF;
    border-color: #ADB5BD #ADB5BD #FFF;
    color: #000; }
  .nav-tabs .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -0.0625rem; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

/* -----------------------------------------------------------------------------
  NAVBAR
*/
.navbar {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem 0rem;
  position: relative; }
  .navbar > .container,
  .navbar > .container-fluid {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  line-height: inherit;
  margin-right: 0rem;
  white-space: nowrap; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0; }
  .navbar-nav .nav-link {
    align-items: center;
    display: flex;
    height: 2.25rem;
    padding-left: 0;
    padding-right: 0; }
  .navbar-nav .dropdown-menu {
    float: none;
    position: static; }
  .navbar-nav .active {
    position: relative; }
    .navbar-nav .active:after {
      border-left: 0.1875rem solid #000;
      bottom: 0;
      content: "";
      left: -0.46875rem;
      position: absolute;
      top: 0; }

/* -----------------------------------------------------------------------------
  NAVBAR: HEADER
*/
.navbar-header-sm {
  border-bottom: 0.125rem solid rgba(33, 37, 41, 0.25); }
  @media (min-width: 30rem) {
    .navbar-header-sm {
      border: 0;
      padding-bottom: 0.5rem; } }

.navbar-header-md {
  border-bottom: 0.125rem solid rgba(33, 37, 41, 0.25); }
  @media (min-width: 48rem) {
    .navbar-header-md {
      border: 0;
      padding-bottom: 0.5rem; } }

.navbar-header-lg {
  border-bottom: 0.125rem solid rgba(33, 37, 41, 0.25); }
  @media (min-width: 62rem) {
    .navbar-header-lg {
      border: 0;
      padding-bottom: 0.5rem; } }

.navbar-header-xl {
  border-bottom: 0.125rem solid rgba(33, 37, 41, 0.25); }
  @media (min-width: 87.5rem) {
    .navbar-header-xl {
      border: 0;
      padding-bottom: 0.5rem; } }

.navbar-header {
  border-bottom: 0.125rem solid rgba(33, 37, 41, 0.25);
  border: 0;
  padding-bottom: 0.5rem; }

/* -----------------------------------------------------------------------------
  NAVBAR: TEXT
*/
.navbar-text {
  display: inline-block;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem; }

/* -----------------------------------------------------------------------------
  NAVBAR: COLLAPSES
*/
.navbar-collapse {
  align-items: center;
  flex-basis: 100%;
  flex-grow: 1; }

@media (max-width: xs) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 30rem) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: 0.75rem;
        padding-right: 0.75rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .active:after {
      border-bottom: 0.1875rem solid #000;
      border-left: 0;
      bottom: -0.5rem;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: inherit; } }

@media (max-width: sm) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 48rem) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-left: 0.75rem;
        padding-right: 0.75rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .active:after {
      border-bottom: 0.1875rem solid #000;
      border-left: 0;
      bottom: -0.5rem;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: inherit; } }

@media (max-width: md) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 62rem) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0.75rem;
        padding-right: 0.75rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .active:after {
      border-bottom: 0.1875rem solid #000;
      border-left: 0;
      bottom: -0.5rem;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: inherit; } }

@media (max-width: lg) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 87.5rem) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 0.75rem;
        padding-right: 0.75rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .active:after {
      border-bottom: 0.1875rem solid #000;
      border-left: 0;
      bottom: -0.5rem;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: inherit; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-left: 0.75rem;
      padding-right: 0.75rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .active:after {
    border-bottom: 0.1875rem solid #000;
    border-left: 0;
    bottom: -0.5rem;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: inherit; }

/* -----------------------------------------------------------------------------
  NAVBAR: THEMES
*/
.navbar-x-net .navbar-brand {
  color: #000; }
  .navbar-x-net .navbar-brand:focus, .navbar-x-net .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-x-net .navbar-nav .nav-link {
  color: #000;
  text-transform: uppercase; }
  .navbar-x-net .navbar-nav .nav-link:focus, .navbar-x-net .navbar-nav .nav-link:hover {
    color: #006893; }
    .navbar-x-net .navbar-nav .nav-link:focus .icon, .navbar-x-net .navbar-nav .nav-link:hover .icon {
      fill: #006893; }
  .navbar-x-net .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-x-net .navbar-nav .show > .nav-link,
.navbar-x-net .navbar-nav .active > .nav-link,
.navbar-x-net .navbar-nav .nav-link.show,
.navbar-x-net .navbar-nav .nav-link.active {
  color: #000; }
  .navbar-x-net .navbar-nav .show > .nav-link .icon,
  .navbar-x-net .navbar-nav .active > .nav-link .icon,
  .navbar-x-net .navbar-nav .nav-link.show .icon,
  .navbar-x-net .navbar-nav .nav-link.active .icon {
    fill: #000; }
  .navbar-x-net .navbar-nav .show > .nav-link:focus, .navbar-x-net .navbar-nav .show > .nav-link:hover,
  .navbar-x-net .navbar-nav .active > .nav-link:focus,
  .navbar-x-net .navbar-nav .active > .nav-link:hover,
  .navbar-x-net .navbar-nav .nav-link.show:focus,
  .navbar-x-net .navbar-nav .nav-link.show:hover,
  .navbar-x-net .navbar-nav .nav-link.active:focus,
  .navbar-x-net .navbar-nav .nav-link.active:hover {
    color: #006893; }
    .navbar-x-net .navbar-nav .show > .nav-link:focus .icon, .navbar-x-net .navbar-nav .show > .nav-link:hover .icon,
    .navbar-x-net .navbar-nav .active > .nav-link:focus .icon,
    .navbar-x-net .navbar-nav .active > .nav-link:hover .icon,
    .navbar-x-net .navbar-nav .nav-link.show:focus .icon,
    .navbar-x-net .navbar-nav .nav-link.show:hover .icon,
    .navbar-x-net .navbar-nav .nav-link.active:focus .icon,
    .navbar-x-net .navbar-nav .nav-link.active:hover .icon {
      fill: #006893; }

.navbar-x-net .navbar-toggler-icon-top,
.navbar-x-net .navbar-toggler-icon-middle,
.navbar-x-net .navbar-toggler-icon-bottom {
  background: #212529; }

.navbar-x-net .navbar-text {
  color: #000; }
  .navbar-x-net .navbar-text a {
    color: #000; }
    .navbar-x-net .navbar-text a:focus, .navbar-x-net .navbar-text a:hover {
      color: #000; }

.navbar-x-net .icon {
  fill: #000; }

/* -----------------------------------------------------------------------------
  NAVBAR: BURGER
*/
@keyframes line-top-in {
  0% {
    bottom: 0;
    left: -0.3125rem;
    transform: rotate(-45deg); }
  20% {
    bottom: 0;
    left: -0.3125rem;
    transform: rotate(-60deg); }
  80% {
    bottom: 0;
    left: 0;
    transform: rotate(10deg); }
  100% {
    bottom: 0.0625rem;
    left: 0;
    transform: rotate(0deg); } }

@keyframes line-top-out {
  0% {
    left: 0;
    top: 0;
    transform: rotate(0deg); }
  20% {
    left: 0;
    top: 0;
    transform: rotate(10deg); }
  80% {
    left: -0.3125rem;
    top: 0;
    transform: rotate(-60deg); }
  100% {
    left: -0.3125rem;
    top: 0.0625rem;
    transform: rotate(-45deg); } }

@keyframes line-bot-in {
  0% {
    left: -0.3125rem;
    transform: rotate(45deg); }
  20% {
    bottom: 0;
    left: -0.3125rem;
    transform: rotate(60deg); }
  80% {
    bottom: 0;
    left: 0;
    transform: rotate(-10deg); }
  100% {
    left: 0;
    transform: rotate(0deg); } }

@keyframes line-bot-out {
  0% {
    left: 0;
    transform: rotate(0deg); }
  20% {
    left: 0;
    transform: rotate(-10deg); }
  80% {
    left: -0.3125rem;
    transform: rotate(60deg); }
  100% {
    left: -0.3125rem;
    transform: rotate(45deg); } }

.navbar-toggler {
  background: none;
  border: 0;
  padding: 0.25rem 0; }
  .navbar-toggler:focus {
    outline: 0; }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon-top {
    animation: line-top-out 600ms linear normal;
    animation-fill-mode: forwards; }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon-middle {
    opacity: 0; }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon-bottom {
    animation: line-bot-out 600ms linear normal;
    animation-fill-mode: forwards; }
  .navbar-toggler.collapsed .navbar-toggler-icon-top {
    animation: line-top-in 600ms linear normal;
    animation-fill-mode: forwards; }
  .navbar-toggler.collapsed .navbar-toggler-icon-middle {
    transition-delay: 200ms; }
  .navbar-toggler.collapsed .navbar-toggler-icon-bottom {
    animation: line-bot-in 600ms linear normal;
    animation-fill-mode: forwards; }

.navbar-toggler-icon {
  cursor: pointer;
  display: block;
  height: 1.625rem;
  position: relative;
  width: 1.875rem; }

.navbar-toggler-icon-top,
.navbar-toggler-icon-middle,
.navbar-toggler-icon-bottom {
  border-radius: 0.125rem;
  display: block;
  height: 0.25rem;
  position: absolute;
  width: 1.875rem; }

.navbar-toggler-icon-top {
  top: 0;
  transform-origin: 1.875rem 0.125rem; }

.navbar-toggler-icon-middle {
  top: 0.6875rem;
  transition: opacity 200ms linear; }

.navbar-toggler-icon-bottom {
  bottom: 0;
  transform-origin: 1.875rem 0.125rem; }

/* -----------------------------------------------------------------------------
  PAGINATION
*/
.pagination {
  list-style: none;
  padding-left: 0;
  border-radius: 0.3125rem;
  display: flex; }

.page-link, .dataTables_paginate .ellipsis {
  background-color: #F8F9FA;
  border: 0.0625rem solid #CED4DA;
  color: #212529;
  cursor: pointer;
  display: block;
  line-height: 1.5;
  margin-left: -0.0625rem;
  padding: 0.4375rem 0.75rem;
  position: relative;
  user-select: none; }
  .page-link:hover, .dataTables_paginate .ellipsis:hover {
    background-color: #DEE2E6;
    border-color: #ADB5BD;
    color: #212529;
    text-decoration: none;
    z-index: 2; }
  .page-link:focus, .dataTables_paginate .ellipsis:focus {
    border-color: #006893;
    box-shadow: 0 0 0 0.1875rem rgba(0, 104, 147, 0.5);
    outline: 0;
    z-index: 3; }

.page-item:first-child .page-link, .dataTables_paginate .ellipsis:first-child .page-link, .page-item:first-child .dataTables_paginate .ellipsis, .dataTables_paginate .page-item:first-child .ellipsis, .dataTables_paginate .ellipsis:first-child .ellipsis {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  margin-left: 0; }

.page-item:last-child .page-link, .dataTables_paginate .ellipsis:last-child .page-link, .page-item:last-child .dataTables_paginate .ellipsis, .dataTables_paginate .page-item:last-child .ellipsis, .dataTables_paginate .ellipsis:last-child .ellipsis {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.page-item.active .page-link, .dataTables_paginate .active.ellipsis .page-link, .page-item.active .dataTables_paginate .ellipsis, .dataTables_paginate .page-item.active .ellipsis, .dataTables_paginate .active.ellipsis .ellipsis {
  background-color: #212529;
  border-color: #16181b;
  color: #FFF;
  z-index: 3; }

.page-item.disabled .page-link, .dataTables_paginate .disabled.ellipsis .page-link, .page-item.disabled .dataTables_paginate .ellipsis, .dataTables_paginate .page-item.disabled .ellipsis, .dataTables_paginate .disabled.ellipsis .ellipsis {
  background-color: #FFF;
  border-color: #495057;
  color: #868E96;
  cursor: auto;
  pointer-events: none; }

/* -----------------------------------------------------------------------------
 PROGRESS
*/
@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem; } }

.progress {
  background-color: #E9ECEF;
  border-radius: 0.3125rem;
  display: flex;
  font-size: 0.75rem;
  height: 1rem;
  overflow: hidden; }

.progress-bar {
  background-color: #90AD25;
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 600ms ease;
  white-space: nowrap; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

/* -----------------------------------------------------------------------------
  RESETS
*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%;
  width: 100%; }

th,
td {
  padding: 0;
  text-align: inherit;
  vertical-align: top; }

thead th {
  vertical-align: bottom; }

/* -----------------------------------------------------------------------------
  TABLE
*/
.table {
  background-color: transparent;
  border-bottom: 0.0625rem solid #ADB5BD;
  margin-bottom: 1rem; }
  .table th,
  .table td {
    border-top: 0.0625rem solid #ADB5BD;
    padding: 1rem; }
    .table th:first-child,
    .table td:first-child {
      padding-left: 2rem; }
    .table th:last-child,
    .table td:last-child {
      padding-right: 2rem; }
  .table thead th {
    border-bottom: 0.125rem solid #ADB5BD;
    border-top: 0; }
  .table tbody + tbody {
    border-top: 0.125rem solid #ADB5BD; }

.table-sm th,
.table-sm td {
  padding: 0.5rem; }
  .table-sm th:first-child,
  .table-sm td:first-child {
    padding-left: 1rem; }
  .table-sm th:last-child,
  .table-sm td:last-child {
    padding-right: 1rem; }

/* -----------------------------------------------------------------------------
  TABLE: LAYOUT
*/
.table-layout-fixed {
  table-layout: fixed; }

/* -----------------------------------------------------------------------------
  TABLE: BORDERLESS
*/
.table-borderless {
  border: 0; }
  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
    border: 0; }

/* -----------------------------------------------------------------------------
  TABLE: STRIPING
*/
.table-striped tbody tr:nth-of-type(even) {
  background-color: #E9ECEF; }

/* -----------------------------------------------------------------------------
  TABLE: HOVER
*/
.table-hover tbody tr:hover {
  background-color: #F8F9FA;
  color: #212529; }

/* -----------------------------------------------------------------------------
  TABLE: BACKGROUND
*/
.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
  color: #212529; }

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a; }

.table-hover .table-black:hover {
  background-color: #ababab; }
  .table-hover .table-black:hover > td,
  .table-hover .table-black:hover > th {
    background-color: #ababab; }

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #f9fafb;
  color: #212529; }

.table-gray-200 th,
.table-gray-200 td,
.table-gray-200 thead th,
.table-gray-200 tbody + tbody {
  border-color: #f4f5f7; }

.table-hover .table-gray-200:hover {
  background-color: #eaedf1; }
  .table-hover .table-gray-200:hover > td,
  .table-hover .table-gray-200:hover > th {
    background-color: #eaedf1; }

.table-gray-300,
.table-gray-300 > th,
.table-gray-300 > td {
  background-color: #f6f7f8;
  color: #212529; }

.table-gray-300 th,
.table-gray-300 td,
.table-gray-300 thead th,
.table-gray-300 tbody + tbody {
  border-color: #eef0f2; }

.table-hover .table-gray-300:hover {
  background-color: #e8eaed; }
  .table-hover .table-gray-300:hover > td,
  .table-hover .table-gray-300:hover > th {
    background-color: #e8eaed; }

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #f1f3f5;
  color: #212529; }

.table-gray-400 th,
.table-gray-400 td,
.table-gray-400 thead th,
.table-gray-400 tbody + tbody {
  border-color: #e6e9ec; }

.table-hover .table-gray-400:hover {
  background-color: #e2e6ea; }
  .table-hover .table-gray-400:hover > td,
  .table-hover .table-gray-400:hover > th {
    background-color: #e2e6ea; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
  color: #212529; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c2c3;
  color: #212529; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-dark:hover {
  background-color: #b4b5b6; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4b5b6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
  color: #212529; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
  color: #212529; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fadbb8;
  color: #212529; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f6bc7b; }

.table-hover .table-primary:hover {
  background-color: #f8cfa0; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #f8cfa0; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e0e8c2;
  color: #212529; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c5d48e; }

.table-hover .table-secondary:hover {
  background-color: #d7e1af; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d7e1af; }

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #b8d5e1;
  color: #212529; }

.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #7ab0c7; }

.table-hover .table-tertiary:hover {
  background-color: #a6cad9; }
  .table-hover .table-tertiary:hover > td,
  .table-hover .table-tertiary:hover > th {
    background-color: #a6cad9; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e0e8c2;
  color: #212529; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #c5d48e; }

.table-hover .table-success:hover {
  background-color: #d7e1af; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d7e1af; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
  color: #212529; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
  color: #212529; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

/* -----------------------------------------------------------------------------
  TABLE: DARK
*/
.table .thead-dark th {
  background-color: #343A40;
  border-color: #454d55;
  color: #FFF; }

.table .thead-light th {
  background-color: #E9ECEF;
  border-color: #ADB5BD;
  color: #495057; }

.table-dark {
  background-color: #343A40;
  color: #FFF; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
    color: #FFF; }

/* -----------------------------------------------------------------------------
  TABLE: RESPONSIVE
*/
@media (max-width: xs) {
  .table-responsive-sm {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: sm) {
  .table-responsive-md {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: md) {
  .table-responsive-lg {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: lg) {
  .table-responsive-xl {
    display: block;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    width: 100%; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  width: 100%; }
  .table-responsive > .table-bordered {
    border: 0; }

/* -----------------------------------------------------------------------------
  TOASTS
*/
.toast-wrapper {
  bottom: 1rem;
  position: fixed;
  right: 0;
  right: 0.46875rem;
  z-index: 2000; }
  @media (min-width: 30rem) {
    .toast-wrapper {
      right: calc(0.9375rem + 0.9375 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .toast-wrapper {
      right: 1.875rem; } }

.toast {
  font-size: 0.875rem;
  max-width: 21.875rem;
  opacity: 0;
  text-align: right; }
  .toast:not(:last-child) {
    margin-bottom: 0.5rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-content {
  background-clip: padding-box;
  background-color: #FFF;
  border: 0rem solid #CED4DA;
  border-radius: 0.3125rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: inline-flex;
  flex-direction: row-reverse;
  overflow: hidden;
  text-align: left; }

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: #E9ECEF;
  border-bottom: 0rem solid #CED4DA;
  color: #868E96;
  display: flex;
  padding: 0.25rem 0.5rem; }

.toast-body {
  margin-left: auto;
  padding: 0.5rem; }

/* -----------------------------------------------------------------------------
  TOOLTIP
*/
.tooltip {
  font-style: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  display: block;
  font-size: 0.875rem;
  margin: 0rem;
  opacity: 0;
  position: absolute;
  word-wrap: break-word;
  z-index: 9000; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    display: block;
    height: 0.5rem;
    position: absolute;
    width: 0.75rem; }
    .tooltip .arrow:before {
      border-color: transparent;
      border-style: solid;
      content: "";
      position: absolute; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.5rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow:before, .bs-tooltip-auto[x-placement^="top"] .arrow:before {
      border-top-color: #212529;
      border-width: 0.5rem 0.375rem 0;
      top: 0; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.5rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    height: 0.75rem;
    left: 0;
    width: 0.5rem; }
    .bs-tooltip-right .arrow:before, .bs-tooltip-auto[x-placement^="right"] .arrow:before {
      border-right-color: #212529;
      border-width: 0.375rem 0.5rem 0.375rem 0;
      right: 0; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.5rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow:before, .bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
      border-bottom-color: #212529;
      border-width: 0 0.375rem 0.5rem;
      bottom: 0; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.5rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    height: 0.75rem;
    right: 0;
    top: 0.5rem; }
    .bs-tooltip-left .arrow:before, .bs-tooltip-auto[x-placement^="left"] .arrow:before {
      border-left-color: #212529;
      border-width: 0.375rem 0 0.375rem 0.5rem;
      left: 0; }

.tooltip-inner {
  background-color: #212529;
  border-radius: 0.3125rem;
  color: #FFF;
  max-width: 12.5rem;
  padding: 0.1875rem 0.375rem;
  text-align: left; }

/* -----------------------------------------------------------------------------
  TRANSITIONS
*/
.fade {
  transition: opacity 150ms linear; }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  position: relative;
  transition: height 350ms ease; }

/* -----------------------------------------------------------------------------
  BODY
*/
body {
  font-size: 0.875rem;
  background-color: #FFF;
  color: #212529;
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  margin: 0;
  min-width: 20rem;
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  text-rendering: geometricPrecision;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 30rem) {
    body {
      font-size: calc(0.875rem + 0.0625 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    body {
      font-size: 0.9375rem; } }

/* -----------------------------------------------------------------------------
  HEADING
*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #000;
  line-height: 1.2;
  margin-top: 0; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small,
  .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small {
    font-size: 85%; }

h1,
.h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  text-transform: uppercase; }
  @media (min-width: 30rem) {
    h1,
    .h1 {
      font-size: calc(1.5rem + 0.3125 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    h1,
    .h1 {
      font-size: 1.8125rem; } }

h2,
.h2 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  text-transform: uppercase; }
  @media (min-width: 30rem) {
    h2,
    .h2 {
      font-size: calc(1.25rem + 0.25 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    h2,
    .h2 {
      font-size: 1.5rem; } }

h3,
.h3 {
  font-size: 1rem;
  margin-bottom: 0.5rem; }
  @media (min-width: 30rem) {
    h3,
    .h3 {
      font-size: calc(1rem + 0.3125 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    h3,
    .h3 {
      font-size: 1.3125rem; } }

h4,
.h4 {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.5rem; }
  @media (min-width: 30rem) {
    h4,
    .h4 {
      font-size: calc(0.875rem + 0.125 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    h4,
    .h4 {
      font-size: 1rem; } }

h5,
.h5 {
  font-size: 0.875rem;
  line-height: 1.5;
  margin-bottom: 0.5rem; }
  @media (min-width: 30rem) {
    h5,
    .h5 {
      font-size: calc(0.875rem + 0.125 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    h5,
    .h5 {
      font-size: 1rem; } }

/* -----------------------------------------------------------------------------
  PARAGRAPGS
*/
p {
  margin-bottom: 0.875rem;
  margin-top: 0; }

/* -----------------------------------------------------------------------------
  LINKS
*/
a {
  background-color: transparent;
  color: #006893;
  fill: #006893;
  text-decoration: none; }
  a:not([href]) {
    color: #212529; }
    a:not([href]):hover {
      color: #212529; }
  a:focus {
    outline: 0.125rem solid #EE7F01;
    outline-offset: 0.0625rem;
    position: relative;
    text-decoration: none;
    z-index: 3000; }
  a:hover {
    color: #004460;
    fill: #004460; }
  a[tabindex="-1"], a[data-focus-method="mouse"], a[data-focus-method="touch"] {
    outline: 0; }
  a.disabled {
    opacity: 0.65;
    pointer-events: none; }

/* -----------------------------------------------------------------------------
  HORIZONTAL RULES
*/
hr {
  border: 0;
  border-top: 0.0625rem solid #CED4DA;
  box-sizing: content-box;
  height: 0;
  margin-bottom: 1rem;
  margin-top: 1rem;
  overflow: visible; }

/* -----------------------------------------------------------------------------
  EMPHASIS
*/
small,
.small {
  font-size: 87.5%; }

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: none; }

/* -----------------------------------------------------------------------------
  LISTS
*/
ol,
ul {
  margin-bottom: 1rem;
  margin-top: 0; }
  ol ol,
  ol ul,
  ul ol,
  ul ul {
    margin-bottom: 0; }

dl {
  margin-bottom: 1rem;
  margin-top: 0; }

.list-styled {
  list-style: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3e%3crect fill='%23EE7F01' height='7' width='7' x='0' y='1'/%3e%3c/svg%3e");
  margin-left: 0;
  padding-left: 1.0625rem; }

.list-unstyled, .errorlist {
  list-style: none;
  padding-left: 0; }

/* -----------------------------------------------------------------------------
  SUB AND SUP
*/
sub,
sup {
  font-size: 0.75em;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.4375em; }

sup {
  top: -0.25em; }

/* -----------------------------------------------------------------------------
  ALIGN
*/
.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

/* -----------------------------------------------------------------------------
  BACKGROUND
*/
.bg-black {
  background-color: #000 !important; }

.bg-gray-200 {
  background-color: #E9ECEF !important; }

.bg-gray-300 {
  background-color: #DEE2E6 !important; }

.bg-gray-400 {
  background-color: #CED4DA !important; }

.bg-danger {
  background-color: #DC3545 !important; }

.bg-dark {
  background-color: #212529 !important; }

.bg-info {
  background-color: #17A2B8 !important; }

.bg-light {
  background-color: #F8F9FA !important; }

.bg-primary {
  background-color: #EE7F01 !important; }

.bg-secondary {
  background-color: #90AD25 !important; }

.bg-tertiary {
  background-color: #006893 !important; }

.bg-success {
  background-color: #90AD25 !important; }

.bg-warning {
  background-color: #FFC107 !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

/* -----------------------------------------------------------------------------
  BORDER
*/
.no-border {
  border: 0 !important; }

.border {
  border: 0.0625rem solid #ADB5BD !important; }

.border-top {
  border-top: 0.0625rem solid #ADB5BD !important; }

.border-right {
  border-right: 0.0625rem solid #ADB5BD !important; }

.border-bottom {
  border-bottom: 0.0625rem solid #ADB5BD !important; }

.border-left {
  border-left: 0.0625rem solid #ADB5BD !important; }

.border-black {
  border-color: #000 !important; }

.border-gray-200 {
  border-color: #E9ECEF !important; }

.border-gray-300 {
  border-color: #DEE2E6 !important; }

.border-gray-400 {
  border-color: #CED4DA !important; }

.border-danger {
  border-color: #DC3545 !important; }

.border-dark {
  border-color: #212529 !important; }

.border-info {
  border-color: #17A2B8 !important; }

.border-light {
  border-color: #F8F9FA !important; }

.border-primary {
  border-color: #EE7F01 !important; }

.border-secondary {
  border-color: #90AD25 !important; }

.border-tertiary {
  border-color: #006893 !important; }

.border-success {
  border-color: #90AD25 !important; }

.border-warning {
  border-color: #FFC107 !important; }

.border-white {
  border-color: #FFF !important; }

/* -----------------------------------------------------------------------------
  BORDER RADIUS
*/
.rounded {
  border-radius: 0.3125rem !important; }

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important; }

.rounded-top-left {
  border-top-left-radius: 0.3125rem !important; }

.rounded-top-right {
  border-top-right-radius: 0.3125rem !important; }

.rounded-right {
  border-bottom-right-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important; }

.rounded-bottom {
  border-bottom-left-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important; }

.rounded-bottom-left {
  border-bottom-left-radius: 0.3125rem !important; }

.rounded-bottom-right {
  border-bottom-right-radius: 0.3125rem !important; }

.rounded-left {
  border-bottom-left-radius: 0.3125rem !important;
  border-top-left-radius: 0.3125rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.text-left {
  text-align: left !important; }

.rounded {
  border-radius: 0.3125rem !important; }

.rounded-top {
  border-top-left-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important; }

.roundedtop-left {
  border-top-left-radius: 0.3125rem !important; }

.rounded-top-right {
  border-top-right-radius: 0.3125rem !important; }

.rounded-right {
  border-bottom-right-radius: 0.3125rem !important;
  border-top-right-radius: 0.3125rem !important; }

.rounded-bottom {
  border-bottom-left-radius: 0.3125rem !important;
  border-bottom-right-radius: 0.3125rem !important; }

.rounded-bottom-left {
  border-bottom-left-radius: 0.3125rem !important; }

.rounded-bottom-right {
  border-bottom-right-radius: 0.3125rem !important; }

.rounded-left {
  border-bottom-left-radius: 0.3125rem !important;
  border-top-left-radius: 0.3125rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

@media (min-width: 30rem) {
  .text-sm-left {
    text-align: left !important; }
  .rounded-sm {
    border-radius: 0.3125rem !important; }
  .rounded-sm-top {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important; }
  .rounded-smtop-left {
    border-top-left-radius: 0.3125rem !important; }
  .rounded-sm-top-right {
    border-top-right-radius: 0.3125rem !important; }
  .rounded-sm-right {
    border-bottom-right-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important; }
  .rounded-sm-bottom {
    border-bottom-left-radius: 0.3125rem !important;
    border-bottom-right-radius: 0.3125rem !important; }
  .rounded-sm-bottom-left {
    border-bottom-left-radius: 0.3125rem !important; }
  .rounded-sm-bottom-right {
    border-bottom-right-radius: 0.3125rem !important; }
  .rounded-sm-left {
    border-bottom-left-radius: 0.3125rem !important;
    border-top-left-radius: 0.3125rem !important; }
  .rounded-sm-0 {
    border-radius: 0 !important; } }

@media (min-width: 48rem) {
  .text-md-left {
    text-align: left !important; }
  .rounded-md {
    border-radius: 0.3125rem !important; }
  .rounded-md-top {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important; }
  .rounded-mdtop-left {
    border-top-left-radius: 0.3125rem !important; }
  .rounded-md-top-right {
    border-top-right-radius: 0.3125rem !important; }
  .rounded-md-right {
    border-bottom-right-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important; }
  .rounded-md-bottom {
    border-bottom-left-radius: 0.3125rem !important;
    border-bottom-right-radius: 0.3125rem !important; }
  .rounded-md-bottom-left {
    border-bottom-left-radius: 0.3125rem !important; }
  .rounded-md-bottom-right {
    border-bottom-right-radius: 0.3125rem !important; }
  .rounded-md-left {
    border-bottom-left-radius: 0.3125rem !important;
    border-top-left-radius: 0.3125rem !important; }
  .rounded-md-0 {
    border-radius: 0 !important; } }

@media (min-width: 62rem) {
  .text-lg-left {
    text-align: left !important; }
  .rounded-lg {
    border-radius: 0.3125rem !important; }
  .rounded-lg-top {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important; }
  .rounded-lgtop-left {
    border-top-left-radius: 0.3125rem !important; }
  .rounded-lg-top-right {
    border-top-right-radius: 0.3125rem !important; }
  .rounded-lg-right {
    border-bottom-right-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important; }
  .rounded-lg-bottom {
    border-bottom-left-radius: 0.3125rem !important;
    border-bottom-right-radius: 0.3125rem !important; }
  .rounded-lg-bottom-left {
    border-bottom-left-radius: 0.3125rem !important; }
  .rounded-lg-bottom-right {
    border-bottom-right-radius: 0.3125rem !important; }
  .rounded-lg-left {
    border-bottom-left-radius: 0.3125rem !important;
    border-top-left-radius: 0.3125rem !important; }
  .rounded-lg-0 {
    border-radius: 0 !important; } }

@media (min-width: 87.5rem) {
  .text-xl-left {
    text-align: left !important; }
  .rounded-xl {
    border-radius: 0.3125rem !important; }
  .rounded-xl-top {
    border-top-left-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important; }
  .rounded-xltop-left {
    border-top-left-radius: 0.3125rem !important; }
  .rounded-xl-top-right {
    border-top-right-radius: 0.3125rem !important; }
  .rounded-xl-right {
    border-bottom-right-radius: 0.3125rem !important;
    border-top-right-radius: 0.3125rem !important; }
  .rounded-xl-bottom {
    border-bottom-left-radius: 0.3125rem !important;
    border-bottom-right-radius: 0.3125rem !important; }
  .rounded-xl-bottom-left {
    border-bottom-left-radius: 0.3125rem !important; }
  .rounded-xl-bottom-right {
    border-bottom-right-radius: 0.3125rem !important; }
  .rounded-xl-left {
    border-bottom-left-radius: 0.3125rem !important;
    border-top-left-radius: 0.3125rem !important; }
  .rounded-xl-0 {
    border-radius: 0 !important; } }

/* -----------------------------------------------------------------------------
  DISPLAY
*/
.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 30rem) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 48rem) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 62rem) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 87.5rem) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

/* -----------------------------------------------------------------------------
  EMBED
*/
.embed-responsive {
  display: block;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%; }
  .embed-responsive:before {
    content: "";
    display: block; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.embed-responsive-21by9:before {
  padding-top: 42.85714%; }

.embed-responsive-16by9:before {
  padding-top: 56.25%; }

.embed-responsive-4by3:before {
  padding-top: 75%; }

.embed-responsive-1by1:before {
  padding-top: 100%; }

/* -----------------------------------------------------------------------------
  FLEX
*/
.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 30rem) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 48rem) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 62rem) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 87.5rem) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

/* -----------------------------------------------------------------------------
  ICON
*/
.icon {
  display: block;
  height: 1.5rem;
  width: 1.5rem; }

.icon-sm {
  height: 1rem;
  width: 1rem; }

.icon-table {
  width: 2.5rem; }

.icon-black {
  fill: #000 !important; }

.icon-gray-200 {
  fill: #E9ECEF !important; }

.icon-gray-300 {
  fill: #DEE2E6 !important; }

.icon-gray-400 {
  fill: #CED4DA !important; }

.icon-danger {
  fill: #DC3545 !important; }

.icon-dark {
  fill: #212529 !important; }

.icon-info {
  fill: #17A2B8 !important; }

.icon-light {
  fill: #F8F9FA !important; }

.icon-primary {
  fill: #EE7F01 !important; }

.icon-secondary {
  fill: #90AD25 !important; }

.icon-tertiary {
  fill: #006893 !important; }

.icon-success {
  fill: #90AD25 !important; }

.icon-warning {
  fill: #FFC107 !important; }

.icon-white {
  fill: #FFF !important; }

[aria-expanded="false"] .icon-down {
  display: block !important; }

[aria-expanded="false"] .icon-up {
  display: none !important; }

[aria-expanded="true"] .icon-down {
  display: none !important; }

[aria-expanded="true"] .icon-up {
  display: block !important; }

/* -----------------------------------------------------------------------------
  POSITION
*/
.fixed-top {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 8030; }

.fixed-bottom {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 8030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 8020; } }

/* -----------------------------------------------------------------------------
  SCREENREADERS
*/
.sr-only {
  border: 0 !important;
  clip: rect(0, 0, 0, 0) !important;
  height: 0.0625rem !important;
  margin: -0.0625rem !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 0.0625rem !important; }

/* -----------------------------------------------------------------------------
  SPACING
*/
.mt-fluid {
  margin-top: 0.46875rem; }
  @media (min-width: 30rem) {
    .mt-fluid {
      margin-top: calc(0.46875rem + 0.46875 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .mt-fluid {
      margin-top: 0.9375rem; } }

.mr-fluid {
  margin-right: 0.46875rem; }
  @media (min-width: 30rem) {
    .mr-fluid {
      margin-right: calc(0.46875rem + 0.46875 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .mr-fluid {
      margin-right: 0.9375rem; } }

.mb-fluid {
  margin-bottom: 0.46875rem; }
  @media (min-width: 30rem) {
    .mb-fluid {
      margin-bottom: calc(0.46875rem + 0.46875 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .mb-fluid {
      margin-bottom: 0.9375rem; } }

.ml-fluid {
  margin-left: 0.46875rem; }
  @media (min-width: 30rem) {
    .ml-fluid {
      margin-left: calc(0.46875rem + 0.46875 * (100vw - 30rem) / (87.5 - 30)); } }
  @media (min-width: 87.5rem) {
    .ml-fluid {
      margin-left: 0.9375rem; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.5rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.5rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important; }

.m-2 {
  margin: 1rem !important; }

.mt-2,
.my-2 {
  margin-top: 1rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1rem !important; }

.m-3 {
  margin: 2rem !important; }

.mt-3,
.my-3 {
  margin-top: 2rem !important; }

.mr-3,
.mx-3 {
  margin-right: 2rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 2rem !important; }

.ml-3,
.mx-3 {
  margin-left: 2rem !important; }

.m-4 {
  margin: 3rem !important; }

.mt-4,
.my-4 {
  margin-top: 3rem !important; }

.mr-4,
.mx-4 {
  margin-right: 3rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 3rem !important; }

.ml-4,
.mx-4 {
  margin-left: 3rem !important; }

.m-5 {
  margin: 4rem !important; }

.mt-5,
.my-5 {
  margin-top: 4rem !important; }

.mr-5,
.mx-5 {
  margin-right: 4rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 4rem !important; }

.ml-5,
.mx-5 {
  margin-left: 4rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.5rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.5rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.5rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.5rem !important; }

.p-2 {
  padding: 1rem !important; }

.pt-2,
.py-2 {
  padding-top: 1rem !important; }

.pr-2,
.px-2 {
  padding-right: 1rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1rem !important; }

.pl-2,
.px-2 {
  padding-left: 1rem !important; }

.p-3 {
  padding: 2rem !important; }

.pt-3,
.py-3 {
  padding-top: 2rem !important; }

.pr-3,
.px-3 {
  padding-right: 2rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 2rem !important; }

.pl-3,
.px-3 {
  padding-left: 2rem !important; }

.p-4 {
  padding: 3rem !important; }

.pt-4,
.py-4 {
  padding-top: 3rem !important; }

.pr-4,
.px-4 {
  padding-right: 3rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 3rem !important; }

.pl-4,
.px-4 {
  padding-left: 3rem !important; }

.p-5 {
  padding: 4rem !important; }

.pt-5,
.py-5 {
  padding-top: 4rem !important; }

.pr-5,
.px-5 {
  padding-right: 4rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 4rem !important; }

.pl-5,
.px-5 {
  padding-left: 4rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 30rem) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.5rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important; }
  .m-sm-2 {
    margin: 1rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important; }
  .m-sm-3 {
    margin: 2rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important; }
  .m-sm-4 {
    margin: 3rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important; }
  .m-sm-5 {
    margin: 4rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.5rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important; }
  .p-sm-2 {
    padding: 1rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important; }
  .p-sm-3 {
    padding: 2rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important; }
  .p-sm-4 {
    padding: 3rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important; }
  .p-sm-5 {
    padding: 4rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 48rem) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.5rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important; }
  .m-md-2 {
    margin: 1rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important; }
  .m-md-3 {
    margin: 2rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important; }
  .m-md-4 {
    margin: 3rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important; }
  .m-md-5 {
    margin: 4rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.5rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important; }
  .p-md-2 {
    padding: 1rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important; }
  .p-md-3 {
    padding: 2rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important; }
  .p-md-4 {
    padding: 3rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important; }
  .p-md-5 {
    padding: 4rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 62rem) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.5rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important; }
  .m-lg-2 {
    margin: 1rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important; }
  .m-lg-3 {
    margin: 2rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important; }
  .m-lg-4 {
    margin: 3rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important; }
  .m-lg-5 {
    margin: 4rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.5rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important; }
  .p-lg-2 {
    padding: 1rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important; }
  .p-lg-3 {
    padding: 2rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important; }
  .p-lg-4 {
    padding: 3rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important; }
  .p-lg-5 {
    padding: 4rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 87.5rem) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.5rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important; }
  .m-xl-2 {
    margin: 1rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important; }
  .m-xl-3 {
    margin: 2rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 2rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 2rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 2rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 2rem !important; }
  .m-xl-4 {
    margin: 3rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important; }
  .m-xl-5 {
    margin: 4rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.5rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important; }
  .p-xl-2 {
    padding: 1rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important; }
  .p-xl-3 {
    padding: 2rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 2rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 2rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 2rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 2rem !important; }
  .p-xl-4 {
    padding: 3rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important; }
  .p-xl-5 {
    padding: 4rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

/* -----------------------------------------------------------------------------
  SIZING
*/
.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

/* -----------------------------------------------------------------------------
  TEXT
*/
.text-black {
  color: #000 !important; }

.text-gray-200 {
  color: #E9ECEF !important; }

.text-gray-300 {
  color: #DEE2E6 !important; }

.text-gray-400 {
  color: #CED4DA !important; }

.text-danger {
  color: #DC3545 !important; }

.text-dark {
  color: #212529 !important; }

.text-info {
  color: #17A2B8 !important; }

.text-light {
  color: #F8F9FA !important; }

.text-primary {
  color: #EE7F01 !important; }

.text-secondary {
  color: #90AD25 !important; }

.text-tertiary {
  color: #006893 !important; }

.text-success {
  color: #90AD25 !important; }

.text-warning {
  color: #FFC107 !important; }

.text-white {
  color: #FFF !important; }

.text-noselect {
  user-select: none; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-normal {
  text-transform: none !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-muted {
  color: #868E96 !important; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

.text-break {
  overflow-wrap: break-word !important;
  word-break: break-word !important; }

.text-left {
  text-align: left !important; }

.text-center {
  text-align: center !important; }

.text-right {
  text-align: right !important; }

@media (min-width: 30rem) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-center {
    text-align: center !important; }
  .text-sm-right {
    text-align: right !important; } }

@media (min-width: 48rem) {
  .text-md-left {
    text-align: left !important; }
  .text-md-center {
    text-align: center !important; }
  .text-md-right {
    text-align: right !important; } }

@media (min-width: 62rem) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-center {
    text-align: center !important; }
  .text-lg-right {
    text-align: right !important; } }

@media (min-width: 87.5rem) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-center {
    text-align: center !important; }
  .text-xl-right {
    text-align: right !important; } }

/* -----------------------------------------------------------------------------
  VISIBILITY
*/
.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/* -----------------------------------------------------------------------------
  OVERFLOW
*/
.overflow-auto {
  overflow: auto !important;
  -webkit-overflow-scrolling: touch; }

.overflow-hidden {
  overflow: hidden !important; }

/* -----------------------------------------------------------------------------
  DATA TABLE
*/
.dt-buttons {
  display: none; }

.dataTables_length {
  display: inline-flex;
  flex-direction: column; }
  .dataTables_length label {
    align-items: center;
    display: flex;
    margin: 0;
    padding: 0; }
  .dataTables_length select {
    margin: 0 0.5rem;
    vertical-align: baseline; }

.dataTables_info {
  display: inline-block;
  line-height: 1.5;
  padding: 0.5625rem 0; }

.dataTables_paginate.paging_simple_numbers {
  display: flex; }
  .dataTables_paginate.paging_simple_numbers > .page-item, .dataTables_paginate.paging_simple_numbers > .ellipsis {
    text-align: center;
    width: 50%; }
    @media (min-width: 62rem) {
      .dataTables_paginate.paging_simple_numbers > .page-item, .dataTables_paginate.paging_simple_numbers > .ellipsis {
        display: none; } }
  .dataTables_paginate.paging_simple_numbers > span {
    display: none; }
    @media (min-width: 62rem) {
      .dataTables_paginate.paging_simple_numbers > span {
        display: flex; } }

.dataTables_paginate > span {
  border-radius: 0.3125rem;
  display: flex;
  list-style: none;
  padding-left: 0; }

.dataTables_paginate .page-item:first-child, .dataTables_paginate .ellipsis:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  margin-left: 0; }

.dataTables_paginate .page-item:last-child, .dataTables_paginate .ellipsis:last-child {
  border-bottom-right-radius: 0.25rem;
  border-top-right-radius: 0.25rem; }

.dataTables_paginate .page-item.active, .dataTables_paginate .active.ellipsis {
  background-color: #212529;
  border-color: #16181b;
  color: #FFF;
  z-index: 3; }

.dataTables_paginate .ellipsis {
  background-color: #FFF;
  color: #868E96;
  cursor: auto;
  pointer-events: none; }

.data-table {
  border-bottom: 0.0625rem solid #ADB5BD;
  margin-bottom: 0.5rem; }
  .data-table .form-control {
    min-width: 4.6875rem; }
  .data-table thead th {
    border-top: 0; }
  .data-table tbody td:focus {
    background-color: #EE7F01;
    color: #FFF;
    outline: 0; }
  .data-table tbody td[data-focus-method="mouse"]:focus, .data-table tbody td[data-focus-method="touch"]:focus {
    background-color: transparent;
    color: #212529; }
  .data-table tbody tr.dtrg-group td {
    background: #E9ECEF; }
  .data-table tfoot {
    display: table-header-group; }
  .data-table.collapsed tbody tr:not(.dtrg-group) td:not(.dataTables_empty):first-child {
    background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3e%3c/svg%3e");
    background-position: left 0.625rem top 0.4375rem;
    background-repeat: no-repeat;
    background-size: 1.25rem 1.25rem;
    cursor: pointer;
    padding-left: 2.125rem; }
    .data-table.collapsed tbody tr:not(.dtrg-group) td:not(.dataTables_empty):first-child:focus {
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23FFF' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3e%3c/svg%3e"); }
    .data-table.collapsed tbody tr:not(.dtrg-group) td:not(.dataTables_empty):first-child[data-focus-method="mouse"]:focus, .data-table.collapsed tbody tr:not(.dtrg-group) td:not(.dataTables_empty):first-child[data-focus-method="touch"]:focus {
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z' /%3e%3c/svg%3e"); }
  .data-table.collapsed tbody tr:not(.dtrg-group) td:not(.dataTables_empty).child {
    padding-left: 1rem; }
    .data-table.collapsed tbody tr:not(.dtrg-group) td:not(.dataTables_empty).child:first-child {
      background: transparent;
      cursor: default; }
  .data-table.collapsed tbody tr:not(.dtrg-group) td:not(.dataTables_empty).datatable-empty {
    background: transparent;
    cursor: default;
    padding-left: 0.75rem; }
  .data-table.collapsed tbody tr:not(.dtrg-group).parent td:first-child {
    background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z' /%3e%3c/svg%3e");
    background-position: left 0.625rem top 0.4375rem;
    background-repeat: no-repeat;
    background-size: 1.25rem 1.25rem; }
    .data-table.collapsed tbody tr:not(.dtrg-group).parent td:first-child:focus {
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%23FFF' d='M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z' /%3e%3c/svg%3e"); }
    .data-table.collapsed tbody tr:not(.dtrg-group).parent td:first-child[data-focus-method="mouse"]:focus, .data-table.collapsed tbody tr:not(.dtrg-group).parent td:first-child[data-focus-method="touch"]:focus {
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z' /%3e%3c/svg%3e"); }
  .data-table .dtr-details {
    list-style: none;
    margin: 0.25rem 0 0;
    padding: 0; }
  .data-table .dtr-title {
    display: block; }
  .data-table .dtr-data {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    overflow-wrap: break-word;
    word-break: break-word; }
    .data-table .dtr-data [data-modal-link] {
      margin-bottom: 0.25rem; }
  .data-table .sorting-asc,
  .data-table .sorting-desc,
  .data-table .sorting {
    background-position: left 0.5rem bottom 0.5625rem;
    background-repeat: no-repeat;
    background-size: 1.25rem 1.25rem;
    cursor: pointer;
    padding-left: 2.0625rem; }
    .data-table .sorting-asc:focus,
    .data-table .sorting-desc:focus,
    .data-table .sorting:focus {
      color: #EE7F01;
      outline: 0; }
    .data-table .sorting-asc:first-child,
    .data-table .sorting-desc:first-child,
    .data-table .sorting:first-child {
      background-position: left 1rem bottom 0.5625rem;
      padding-left: 2.5625rem; }
  .data-table .sorting {
    background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z'/%3e%3c/svg%3e"); }
    .data-table .sorting:focus {
      background-color: #EE7F01 !important;
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23FFF' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z'/%3e%3c/svg%3e");
      border-color: #212529;
      color: #FFF; }
    .data-table .sorting[data-focus-method="mouse"]:focus, .data-table .sorting[data-focus-method="touch"]:focus {
      background-color: #E9ECEF !important;
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V7H1.5L5,3.5L8.5,7H6V17Z'/%3e%3c/svg%3e");
      color: #212529; }
  .data-table .sorting-asc {
    background-color: #CED4DA !important;
    background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M10,11V13H18V11H10M10,5V7H14V5H10M10,17V19H22V17H10M6,7H8.5L5,3.5L1.5,7H4V20H6V7Z' /%3e%3c/svg%3e"); }
    .data-table .sorting-asc:focus {
      background-color: #EE7F01 !important;
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23FFF' d='M10,11V13H18V11H10M10,5V7H14V5H10M10,17V19H22V17H10M6,7H8.5L5,3.5L1.5,7H4V20H6V7Z' /%3e%3c/svg%3e");
      border-color: #212529;
      color: #FFF; }
    .data-table .sorting-asc[data-focus-method="mouse"]:focus, .data-table .sorting-asc[data-focus-method="touch"]:focus {
      background-color: #E9ECEF !important;
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M10,11V13H18V11H10M10,5V7H14V5H10M10,17V19H22V17H10M6,7H8.5L5,3.5L1.5,7H4V20H6V7Z' /%3e%3c/svg%3e");
      color: #212529; }
  .data-table .sorting-desc {
    background-color: #CED4DA !important;
    background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V4H6V17Z' /%3e%3c/svg%3e"); }
    .data-table .sorting-desc:focus {
      background-color: #EE7F01 !important;
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23FFF' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V4H6V17Z' /%3e%3c/svg%3e");
      border-color: #212529;
      color: #FFF; }
    .data-table .sorting-desc[data-focus-method="mouse"]:focus, .data-table .sorting-desc[data-focus-method="touch"]:focus {
      background-color: #E9ECEF !important;
      background-image: url("data:image/svg+xml;utf8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23212529' d='M10,13V11H18V13H10M10,19V17H14V19H10M10,7V5H22V7H10M6,17H8.5L5,20.5L1.5,17H4V4H6V17Z' /%3e%3c/svg%3e");
      color: #212529; }

@keyframes processing-loader {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.data-table .processing {
  background-color: rgba(0, 104, 147, 0.1);
  border: 0;
  display: table-cell !important; }

.data-table .processing-loader {
  height: 1.3125rem;
  width: 1.3125rem; }
  .data-table .processing-loader:after {
    animation: processing-loader 800ms linear infinite;
    border: 0.1875rem solid rgba(0, 104, 147, 0.4);
    border-radius: 100%;
    border-top-color: #006893;
    content: "";
    display: block;
    height: 1.3125rem;
    transition: opacity;
    width: 1.3125rem; }

/* -----------------------------------------------------------------------------
  CUSTOM
*/
.data-table .custom-select {
  min-width: 4.375rem; }

body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox {
  /* Fix iOS */
  /* Buttons */
  /* Fix IE11 */
  /* Close button on the top right corner of html content */
  /* Navigation arrows */
  /* Caption */
  /* Loading indicator */
  /* Transition effects */
  /* transitionEffect: slide */
  /* transitionEffect: fade */
  /* transitionEffect: zoom-in-out */
  /* transitionEffect: rotate */
  /* transitionEffect: circular */
  /* transitionEffect: tube */
  /* Styling for Small-Screen Devices */
  /* Share */
  /* Thumbs */
  /* Styling for Small-Screen Devices */ }
  .fancybox-active {
    height: auto; }
  .fancybox-is-hidden {
    left: -624.9375rem;
    margin: 0;
    position: absolute !important;
    top: -624.9375rem;
    visibility: hidden; }
  .fancybox-container {
    backface-visibility: hidden;
    height: 100%;
    left: 0;
    outline: none;
    position: fixed;
    -webkit-tap-highlight-color: transparent;
    top: 0;
    touch-action: manipulation;
    transform: translateZ(0);
    width: 100%;
    z-index: 99992; }
  .fancybox-outer, .fancybox-inner, .fancybox-bg, .fancybox-stage {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0; }
  .fancybox-outer {
    -webkit-overflow-scrolling: touch;
    overflow-y: auto; }
  .fancybox-bg {
    background: #0D0D0D;
    opacity: 0;
    transition-duration: inherit;
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }
  .fancybox-is-open .fancybox-bg {
    opacity: 1;
    transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }
  .fancybox-infobar, .fancybox-toolbar, .fancybox-caption,
  .fancybox-navigation .fancybox-button {
    direction: ltr;
    opacity: 0;
    position: absolute;
    transition: opacity 0.25s ease, visibility 0s ease 0.25s;
    visibility: hidden;
    z-index: 99997; }
  .fancybox-show-infobar .fancybox-infobar,
  .fancybox-show-toolbar .fancybox-toolbar,
  .fancybox-show-caption .fancybox-caption,
  .fancybox-show-nav .fancybox-navigation .fancybox-button {
    opacity: 1;
    transition: opacity 0.25s ease 0s, visibility 0s ease 0s;
    visibility: visible; }
  .fancybox-infobar {
    color: #CCC;
    font-size: 0.8125rem;
    height: 2.75rem;
    left: 0;
    line-height: 2.75rem;
    min-width: 2.75rem;
    mix-blend-mode: difference;
    padding: 0 0.625rem;
    pointer-events: none;
    top: 0;
    user-select: none; }
  .fancybox-toolbar {
    right: 0;
    top: 0; }
  .fancybox-stage {
    direction: ltr;
    overflow: visible;
    transform: translateZ(0);
    z-index: 99994; }
  .fancybox-is-open .fancybox-stage {
    overflow: hidden; }
  .fancybox-slide {
    backface-visibility: hidden;
    display: none;
    height: 100%;
    left: 0;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 2.75rem;
    position: absolute;
    text-align: center;
    top: 0;
    transition-property: transform, opacity;
    white-space: normal;
    width: 100%;
    z-index: 99994; }
  .fancybox-slide:before {
    content: "";
    display: inline-block;
    font-size: 0;
    height: 100%;
    vertical-align: middle;
    width: 0; }
  .fancybox-is-sliding .fancybox-slide, .fancybox-slide--previous, .fancybox-slide--current, .fancybox-slide--next {
    display: block; }
  .fancybox-slide--image {
    overflow: hidden;
    padding: 2.75rem 0; }
  .fancybox-slide--image:before {
    display: none; }
  .fancybox-slide--html {
    padding: 0.375rem; }
  .fancybox-content {
    background: #FFF;
    display: inline-block;
    margin: 0;
    max-width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: 2.75rem;
    position: relative;
    text-align: left;
    vertical-align: middle; }
  .fancybox-slide--image .fancybox-content {
    animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
    backface-visibility: hidden;
    background: transparent;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    left: 0;
    max-width: none;
    overflow: visible;
    padding: 0;
    position: absolute;
    top: 0;
    transform-origin: top left;
    transition-property: transform, opacity;
    user-select: none;
    z-index: 99995; }
  .fancybox-can-zoomOut .fancybox-content {
    cursor: zoom-out; }
  .fancybox-can-zoomIn .fancybox-content {
    cursor: zoom-in; }
  .fancybox-can-swipe .fancybox-content,
  .fancybox-can-pan .fancybox-content {
    cursor: grab; }
  .fancybox-is-grabbing .fancybox-content {
    cursor: grabbing; }
  .fancybox-container [data-selectable="true"] {
    cursor: text; }
  .fancybox-image, .fancybox-spaceball {
    background: transparent;
    border: 0;
    height: 100%;
    left: 0;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    position: absolute;
    top: 0;
    user-select: none;
    width: 100%; }
  .fancybox-spaceball {
    z-index: 1; }
  .fancybox-slide--video .fancybox-content,
  .fancybox-slide--map .fancybox-content,
  .fancybox-slide--pdf .fancybox-content,
  .fancybox-slide--iframe .fancybox-content {
    height: 100%;
    overflow: visible;
    padding: 0;
    width: 100%; }
  .fancybox-slide--video .fancybox-content {
    background: #000; }
  .fancybox-slide--map .fancybox-content {
    background: #E5E3DF; }
  .fancybox-slide--iframe .fancybox-content {
    background: #FFF; }
  .fancybox-video, .fancybox-iframe {
    background: transparent;
    border: 0;
    display: block;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%; }
  .fancybox-iframe {
    left: 0;
    position: absolute;
    top: 0; }
  .fancybox-error {
    background: #FFF;
    cursor: default;
    max-width: 25rem;
    padding: 2.5rem;
    width: 100%; }
  .fancybox-error p {
    color: #444;
    font-size: 1rem;
    line-height: 1.25rem;
    margin: 0;
    padding: 0; }
  .fancybox-button {
    background: rgba(30, 30, 30, 0.6);
    border: 0;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    height: 2.75rem;
    margin: 0;
    padding: 0.625rem;
    position: relative;
    transition: color 0.2s;
    vertical-align: top;
    visibility: inherit;
    width: 2.75rem; }
  .fancybox-button, .fancybox-button:visited, .fancybox-button:link {
    color: #CCC; }
  .fancybox-button:hover {
    color: #FFF; }
  .fancybox-button:focus {
    outline: none; }
  .fancybox-button.fancybox-focus {
    outline: 0.0625rem dotted; }
  .fancybox-button[disabled], .fancybox-button[disabled]:hover {
    color: #888;
    cursor: default;
    outline: none; }
  .fancybox-button div {
    height: 100%; }
  .fancybox-button svg {
    display: block;
    height: 100%;
    overflow: visible;
    position: relative;
    width: 100%; }
  .fancybox-button svg path {
    fill: currentColor;
    stroke-width: 0; }
  .fancybox-button--play svg:nth-child(2),
  .fancybox-button--fsenter svg:nth-child(2) {
    display: none; }
  .fancybox-button--pause svg:nth-child(1),
  .fancybox-button--fsexit svg:nth-child(1) {
    display: none; }
  .fancybox-progress {
    background: #FF5268;
    height: 0.125rem;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform: scaleX(0);
    transform-origin: 0;
    transition-property: transform;
    transition-timing-function: linear;
    z-index: 99998; }
  .fancybox-close-small {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #CCC;
    cursor: pointer;
    opacity: 0.8;
    padding: 0.5rem;
    position: absolute;
    right: -0.75rem;
    top: -2.75rem;
    z-index: 401; }
  .fancybox-close-small:hover {
    color: #FFF;
    opacity: 1; }
  .fancybox-slide--html .fancybox-close-small {
    color: currentColor;
    padding: 0.625rem;
    right: 0;
    top: 0; }
  .fancybox-slide--image.fancybox-is-scaling .fancybox-content {
    overflow: hidden; }
  .fancybox-is-scaling .fancybox-close-small,
  .fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
    display: none; }
  .fancybox-navigation .fancybox-button {
    background-clip: content-box;
    height: 6.25rem;
    opacity: 0;
    position: absolute;
    top: calc(50% - 3.125rem);
    width: 4.375rem; }
  .fancybox-navigation .fancybox-button div {
    padding: 0.4375rem; }
  .fancybox-navigation .fancybox-button--arrow_left {
    left: 0;
    left: env(safe-area-inset-left);
    padding: 1.9375rem 1.625rem 1.9375rem 0.375rem; }
  .fancybox-navigation .fancybox-button--arrow_right {
    padding: 1.9375rem 0.375rem 1.9375rem 1.625rem;
    right: 0;
    right: env(safe-area-inset-right); }
  .fancybox-caption {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
    bottom: 0;
    color: #EEE;
    font-size: 0.875rem;
    font-weight: 400;
    left: 0;
    line-height: 1.5;
    padding: 4.6875rem 2.75rem 1.5625rem 2.75rem;
    pointer-events: none;
    right: 0;
    text-align: center;
    z-index: 99996; }
  .fancybox-caption--separate {
    margin-top: -3.125rem; }
  .fancybox-caption__body {
    max-height: 50vh;
    overflow: auto;
    pointer-events: all; }
  .fancybox-caption a,
  .fancybox-caption a:link,
  .fancybox-caption a:visited {
    color: #CCC;
    text-decoration: none; }
  .fancybox-caption a:hover {
    color: #FFF;
    text-decoration: underline; }
  .fancybox-loading {
    animation: fancybox-rotate 1s linear infinite;
    background: transparent;
    border: 0.25rem solid #888;
    border-bottom-color: #FFF;
    border-radius: 50%;
    height: 3.125rem;
    left: 50%;
    margin: -1.5625rem 0 0 -1.5625rem;
    opacity: 0.7;
    padding: 0;
    position: absolute;
    top: 50%;
    width: 3.125rem;
    z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }
  .fancybox-animated {
    transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }
  .fancybox-fx-slide.fancybox-slide--previous {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  .fancybox-fx-slide.fancybox-slide--next {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  .fancybox-fx-slide.fancybox-slide--current {
    opacity: 1;
    transform: translate3d(0, 0, 0); }
  .fancybox-fx-fade.fancybox-slide--previous, .fancybox-fx-fade.fancybox-slide--next {
    opacity: 0;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .fancybox-fx-fade.fancybox-slide--current {
    opacity: 1; }
  .fancybox-fx-zoom-in-out.fancybox-slide--previous {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1.5); }
  .fancybox-fx-zoom-in-out.fancybox-slide--next {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 0.5); }
  .fancybox-fx-zoom-in-out.fancybox-slide--current {
    opacity: 1;
    transform: scale3d(1, 1, 1); }
  .fancybox-fx-rotate.fancybox-slide--previous {
    opacity: 0;
    transform: rotate(-360deg); }
  .fancybox-fx-rotate.fancybox-slide--next {
    opacity: 0;
    transform: rotate(360deg); }
  .fancybox-fx-rotate.fancybox-slide--current {
    opacity: 1;
    transform: rotate(0deg); }
  .fancybox-fx-circular.fancybox-slide--previous {
    opacity: 0;
    transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }
  .fancybox-fx-circular.fancybox-slide--next {
    opacity: 0;
    transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }
  .fancybox-fx-circular.fancybox-slide--current {
    opacity: 1;
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }
  .fancybox-fx-tube.fancybox-slide--previous {
    transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }
  .fancybox-fx-tube.fancybox-slide--next {
    transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }
  .fancybox-fx-tube.fancybox-slide--current {
    transform: translate3d(0, 0, 0) scale(1); }
  @media (max-width: 29.9375rem) {
    .fancybox-slide {
      padding-left: 0.375rem;
      padding-right: 0.375rem; }
    .fancybox-slide--image {
      padding: 0.375rem 0; }
    .fancybox-close-small {
      right: -0.375rem; }
    .fancybox-slide--image .fancybox-close-small {
      background: #4E4E4E;
      color: #F2F4F6;
      height: 2.25rem;
      opacity: 1;
      padding: 0.375rem;
      right: 0;
      top: 0;
      width: 2.25rem; }
    .fancybox-caption {
      padding-left: 0.75rem;
      padding-right: 0.75rem; } }
  .fancybox-share {
    background: #F4F4F4;
    border-radius: 0.1875rem;
    max-width: 90%;
    padding: 1.875rem;
    text-align: center; }
  .fancybox-share h1 {
    color: #222;
    font-size: 2.1875rem;
    font-weight: 700;
    margin: 0 0 1.25rem 0; }
  .fancybox-share p {
    margin: 0;
    padding: 0; }
  .fancybox-share__button {
    border: 0;
    border-radius: 0.1875rem;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 2.5rem;
    margin: 0 0.3125rem 0.625rem 0.3125rem;
    min-width: 8.125rem;
    padding: 0 0.9375rem;
    text-decoration: none;
    transition: all 0.2s;
    user-select: none;
    white-space: nowrap; }
  .fancybox-share__button:visited, .fancybox-share__button:link {
    color: #FFF; }
  .fancybox-share__button:hover {
    text-decoration: none; }
  .fancybox-share__button--fb {
    background: #3B5998; }
  .fancybox-share__button--fb:hover {
    background: #344E86; }
  .fancybox-share__button--pt {
    background: #BD081D; }
  .fancybox-share__button--pt:hover {
    background: #AA0719; }
  .fancybox-share__button--tw {
    background: #1DA1F2; }
  .fancybox-share__button--tw:hover {
    background: #0D95E8; }
  .fancybox-share__button svg {
    height: 1.5625rem;
    margin-right: 0.4375rem;
    position: relative;
    top: -0.0625rem;
    vertical-align: middle;
    width: 1.5625rem; }
  .fancybox-share__button svg path {
    fill: #FFF; }
  .fancybox-share__input {
    background: transparent;
    border: 0;
    border-bottom: 0.0625rem solid #D7D7D7;
    border-radius: 0;
    color: #5D5B5B;
    font-size: 0.875rem;
    margin: 0.625rem 0 0 0;
    outline: none;
    padding: 0.625rem 0.9375rem;
    width: 100%; }
  .fancybox-thumbs {
    background: #DDD;
    bottom: 0;
    display: none;
    margin: 0;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    padding: 0.125rem 0.125rem 0.25rem 0.125rem;
    position: absolute;
    right: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    width: 13.25rem;
    z-index: 99995; }
  .fancybox-thumbs-x {
    overflow-x: auto;
    overflow-y: hidden; }
  .fancybox-show-thumbs .fancybox-thumbs {
    display: block; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 13.25rem; }
  .fancybox-thumbs__list {
    font-size: 0;
    height: 100%;
    list-style: none;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
    position: absolute;
    position: relative;
    white-space: nowrap;
    width: 100%; }
  .fancybox-thumbs-x .fancybox-thumbs__list {
    overflow: hidden; }
  .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
    width: 0.4375rem; }
  .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
    background: #FFF;
    border-radius: 0.625rem;
    box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3); }
  .fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
    background: #2A2A2A;
    border-radius: 0.625rem; }
  .fancybox-thumbs__list a {
    backface-visibility: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    float: left;
    height: 4.6875rem;
    margin: 0.125rem;
    max-height: calc(100% - 0.5rem);
    max-width: calc(50% - 0.25rem);
    outline: none;
    overflow: hidden;
    padding: 0;
    position: relative;
    -webkit-tap-highlight-color: transparent;
    width: 6.25rem; }
  .fancybox-thumbs__list a:before {
    border: 0.375rem solid #FF5268;
    bottom: 0;
    content: "";
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 99991; }
  .fancybox-thumbs__list a:focus:before {
    opacity: 0.5; }
  .fancybox-thumbs__list a.fancybox-thumbs-active:before {
    opacity: 1; }
  @media (max-width: 29.9375rem) {
    .fancybox-thumbs {
      width: 6.875rem; }
    .fancybox-show-thumbs .fancybox-inner {
      right: 6.875rem; }
    .fancybox-thumbs__list a {
      max-width: calc(100% - 0.625rem); } }

/* -----------------------------------------------------------------------------
  FILE TREE
*/
.file-tree {
  border: 0.0625rem solid #ADB5BD;
  border-radius: 0.3125rem;
  height: 13rem;
  margin: 0.5rem 0;
  overflow-y: auto;
  padding: 0.5rem 0.5rem;
  position: relative; }
  .file-tree ul {
    margin: 0;
    padding: 0; }
  .file-tree li {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23EE7F01' d='M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M18,20H6V4H13V9H18V20Z' /%3e%3c/svg%3e");
    background-position: 0 0.25rem;
    background-repeat: no-repeat;
    background-size: 1.5rem;
    line-height: 1.75rem;
    padding: 0 0 0 1.625rem;
    white-space: nowrap; }
    .file-tree li.wait {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23EE7F01' d='M6,2H18V8H18V8L14,12L18,16V16H18V22H6V16H6V16L10,12L6,8V8H6V2M16,16.5L12,12.5L8,16.5V20H16V16.5M12,11.5L16,7.5V4H8V7.5L12,11.5M10,6H14V6.75L12,8.75L10,6.75V6Z'/%3e%3c/svg%3e"); }
    .file-tree li.directory {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23EE7F01' d='M20,18H4V8H20M20,6H12L10,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V8C22,6.89 21.1,6 20,6Z'/%3e%3c/svg%3e"); }
    .file-tree li.expanded {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath fill='%23EE7F01' d='M6.1,10L4,18V8H21A2,2 0 0,0 19,6H12L10,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H19C19.9,20 20.7,19.4 20.9,18.5L23.2,10H6.1M19,18H6L7.6,12H20.6L19,18Z'/%3e%3c/svg%3e"); }
    .file-tree li li {
      background-position: 0.125rem 0.25rem; }
  .file-tree a {
    color: #212529;
    line-height: 1.75rem;
    margin-left: 0.25rem;
    padding: 0.125rem 0.375rem;
    text-decoration: none; }
    .file-tree a:hover {
      color: #EE7F01; }
    .file-tree a.selected {
      background: #CED4DA;
      color: #212529; }

.is-invalid ~ .file-tree {
  border-color: #DC3545; }

/* -----------------------------------------------------------------------------
  DEFAULTS
*/
*,
*:before,
*:after {
  box-sizing: border-box; }

/* -----------------------------------------------------------------------------
  HEADER
*/
@keyframes color-animation {
  0% {
    background-position: 22% 0%; }
  100% {
    background-position: 100% 0%; } }

.color-animation, .modal-header:after, .color-header {
  background: linear-gradient(90deg, #00B33A, #00ACF1, #00B33A, #FFA000, #00B33A, #00ACF1, #00B33A, #FFA000, #00B33A);
  background-position: 22% 0;
  background-size: 300% 100%; }

.color-header {
  height: 0.875rem; }

/* -----------------------------------------------------------------------------
  MAIN
*/
html {
  height: 100%; }

body {
  display: flex;
  flex-direction: column;
  min-height: 100%; }

main {
  display: block;
  flex: 1 0 auto; }

/* -----------------------------------------------------------------------------
  LOGIN
*/
@media (min-width: 62rem) {
  .login {
    min-height: 100vh; } }

/* -----------------------------------------------------------------------------
  CKEDITOR
*/
.django-ckeditor-widget {
  display: block !important; }

.django-ckeditor-widget.is-invalid {
  border-style: solid;
  border-color: red; }
  .django-ckeditor-widget.is-invalid ~ .invalid-feedback {
    display: block; }

.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block; }

.custom_select_datatable {
  height: calc(var(--x-input-line-height) * 0.5em + var(--x-input-padding-y) * 2 + var(--x-input-height-border)); }
